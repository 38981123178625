import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import useReactRouter from "use-react-router";
import { NEW_API_URL, API_KEY } from "../../../common/constraint";
import * as XLSX from "xlsx";
import "../../../index.css";
import { USER_KEY } from "../../../consts/user";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@material-ui/core";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import LoadingDialog from "../../../common/LoadingDialog";
import StatusDialog from "../../../common/StatusDialog";

export const GiveApartGrade = () => {
  const { location, match } = useReactRouter();
  const dataProp = location.state;
  const [students, setStudents] = useState([]);
  const [classRoom, setClassRoom] = useState("");
  const [courseData, setCourseData] = useState("");
  const [courseClassroom, setCourseClassroom] = useState();
  const [scoreType, setScoreType] = useState();

  const [errorMessage, setErrorMessage] = useState("");
  const [importLoading, setImportLoading] = useState(false);
  const [importSuccess, setImportSuccess] = useState(false);

  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;

  useEffect(() => {
    if (dataProp) {
      if (dataProp.courseClassroom.length > 0) {
        setClassRoom(dataProp?.courseClassroom[0].classRoom?.id);
        setFilters({
          assessmentYear: dataProp?.courseData.assessmentYear,
          year: dataProp?.courseData.year,
          semester: dataProp?.courseData.semester,
          courseId: dataProp?.courseData.id,
        });
      }

      setCourseClassroom(dataProp?.courseClassroom[0]);
      setCourseData(dataProp?.courseData);
    }
  }, [dataProp]);

  const [scoreStatus, setScoreStatus] = useState("");
  const [gradeIdList, setGradeIdList] = useState([]);

  // Filter body to send to APO
  const [filters, setFilters] = useState();

  useEffect(() => {
    if (students.length > 0) {
      const _studentIds = students.map((student) => {
        return student._id;
      });
      setGradeIdList(_studentIds);
    }
  }, [students]);

  const fetchStudent = async () => {
    if (courseData) {
      await axios
        .get(
          NEW_API_URL +
            `student/class/${courseData?.assessmentYear}/${
              courseData.id || ""
            }`,
          {
            headers: {
              api_key: API_KEY,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setStudents(res.data?.students);
          if (res.data?.students.length > 0) {
            setScoreStatus(
              res.data?.students[0]?.sendingGradeStatus || "TEACHER_SCORING"
            );
            setScoreType(res.data?.courseDetail?.course?.gradeType);
          }
        })
        .catch((err) => {
          // console.log("fetch student error");
          // console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchStudent();
  }, [courseData]);

  const [reqBody, setReqBody] = useState({});
  const [selectedId, setSelectedid] = useState("");
  const [ImportError, setIsImportError] = useState(false);

  const onClik = (id) => {
    setSelectedid(id);
    setScores([]);
  };

  const onExport = () => {
    const gradeTypeData = scoreType.map((type) => {
      return type?.name;
    });

    const title = [
      "ຄະນະ: " + courseClassroom?.faculty.name,
      " ພາກ: " + courseClassroom?.department?.name,
      "ສາຂາ: " + courseClassroom?.major?.name,
      "ຫ້ອງ: " + courseClassroom?.classRoom?.name,
      "ຊື່ວິຊາ: " + courseData?.title,
      "ປີຮຽນ: " + courseData?.year,
      "ພາກຮຽນ: " + courseData?.semester,
    ];
    const headerData = [
      ["ລຳດັບ", "ລະຫັດນັກສຶກສາ", "ຊື່ ແລະ ນາມສະກຸນ", ...gradeTypeData],
    ];

    // Convert array of objects to array of arrays
    const studentsData = students.map((student, index) => {
      let gender = "";
      switch (student?.student?.gender) {
        case "MALE":
          gender = "ທ້າວ";
          break;
        case "FEMALE":
          gender = "ນາງ";
          break;
        case "MONK":
          gender = "ພຣະ";
          break;
      }

      const scores = student?.gradeType.map(
        (score) => score?.score || ""
      );

      return [
        index + 1,
        student?.student?.userId,
        gender +
          " " +
          student?.student?.firstNameL +
          student?.student?.lastNameL,
        ...scores,
      ];
    });

    // Combine headerData and studentsData
    const worksheetData = [title, ...headerData, ...studentsData];

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook to a file
    const excelFileName = "example.xlsx";
    XLSX.writeFile(workbook, excelFileName);
  };
  const [invalidFormat, setInvalidFormat] = useState(false);

  // Function for case-insensitive and space-insensitive string comparison
  function compareWithoutSpacesAndCase(str1, str2) {
    const formattedStr1 = (str1 ?? "").replace(/\s/g, "").toLowerCase();
    const formattedStr2 = (str2 ?? "").replace(/\s/g, "").toLowerCase();
    return formattedStr1 === formattedStr2;
  }

  const onImport = (event) => {
    const file = event.target.files[0];

    if (!file) {
      console.error("No file selected");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      setImportLoading(true);
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming there is only one sheet in the workbook
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      // Convert the sheet data to an array of objects
      const _importedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const isStudentHaveGradeType = _importedData[1].length > 3;
      const isFirstRowMatch = _importedData[0].length === 7;

      const isRowsValid = isStudentHaveGradeType && isFirstRowMatch;

      if (isRowsValid) {
        const _gradeType = _importedData[1].slice(3);
        const _studentData = _importedData.slice(2);
        const _classRoom = _importedData[0][3];
        const _year = _importedData[0][5];
        const _semester = _importedData[0][6];
        const _courseName = _importedData[0][4];

        let isStudentHaveNullId = false;
        _studentData.map((student) => {
          const studentId = student[1];
          if (!studentId) {
            isStudentHaveNullId = true;
            setImportLoading(false);
            setErrorMessage("ມິນັກສຶກສາທິມິໄອດີເປັນຄ່າວ່າງເປົ່າ");
            setIsImportError(true);
          }
        });

        if (!isStudentHaveNullId) {
          let isStudentScoreHaveNull = false;
          _studentData.map((student) => {
            const studentScore = student.slice(3);
            if (studentScore.length !== _gradeType.length) {
              isStudentScoreHaveNull = true;
              setImportLoading(false);
              setErrorMessage("ມິນັກສຶກສາທິມິຄະເເນນເປັນຄ່າວ່າງເປົ່າ");
              setIsImportError(true);
              return;
            }
          });

          if (!isStudentScoreHaveNull) {
            const bodyData = {
              course: courseData.id,
              gradeType: _gradeType,
              students: _studentData,
              classRoom: _classRoom,
              year: _year,
              semester: _semester,
            };
            const year = bodyData.year.split(":")[1];
            const semester = bodyData.semester.split(":")[1];
            const courseName = _courseName.split(":")[1];

            const formatInfoIsMatched =
              parseInt(semester) === parseInt(courseData.semester) &&
              parseInt(year) === parseInt(courseData.year) &&
              compareWithoutSpacesAndCase(
                bodyData?.facName,
                courseData?.faculty?.name
              ) &&
              compareWithoutSpacesAndCase(courseName, courseData?.title);
            if (formatInfoIsMatched) {
              importScore(bodyData);
            } else {
              setImportLoading(false);
              setErrorMessage("ຂໍ້ມູນຄະນະ, ພາກ, ສາຂາ ຫລື ຫ້ອງບໍ່ຕົງກັນ");
              setIsImportError(true);
            }
          }
        }
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const importScore = async (data) => {
    try {
      const response = await axios.post(NEW_API_URL + "score/import", data, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("response => ", response.data.scoreOutOflimit);

      if (response?.data?.scoreOutOflimit === true) {
        setImportLoading(false);
        setIsImportError(true);
        setErrorMessage("ມີນັກສຶກສາທີ່ມິຄະເເນນສູງກວ່າ 100");
      } else {
        setIsImportError(false);
        setImportLoading(false);
        setImportSuccess(true);
        window.location.reload();
      }
    } catch (err) {
      setIsImportError(true);
      setImportLoading(false);
      setErrorMessage("ຂໍອະໄພ ລະບົບເກີດບັນຫາໃນການ Import");
    }
  };

  const [scores, setScores] = useState([]);

  useEffect(() => {
    if (importSuccess) {
      setTimeout(() => {
        setImportSuccess(false);
      }, 2000);
    }
  }, [importSuccess, ImportError]);

  const handleInputChange = (e, data, assessmentId, name, maxScore) => {
    const { value } = e.target;

    const existingIndex = scores.findIndex(
      (score) => score._id === assessmentId
    );

    if (existingIndex !== -1) {
      // If it exists, update the existing item
      const updatedScores = [...scores];
      updatedScores[existingIndex] = {
        _id: assessmentId,
        score: value,
        name: name,
        maxScore: maxScore,
      };
      setScores(updatedScores);
    } else {
      // If it doesn't exist, add a new item
      setScores([
        ...scores,
        {
          _id: assessmentId,
          score: value,
          name: name,
          maxScore: maxScore,
        },
      ]);
    }

    const studentId = data.student._id;
    const body = {
      student: studentId,
      course: courseData?.id,
      yearLevel: courseData?.year,
      semester: courseData?.semester,
      assessmentYear: courseData?.assessmentYear,
      sendingGradeStatus: "TEACHER_SCORING",
    };

    setReqBody(body);
  };

  const saveScore = async (data, student) => {
    const currentScore = student?.gradeType;

    const updateScoreName = data?.gradeType[0]?.name;
    const newScore = data?.gradeType[0]?.score;

    const updatedScores = currentScore.map((item) => {
      if (item.name === updateScoreName) {
        return {
          ...item,
          score: newScore,
        };
      }
      return item;
    });

    let sum = 0;

    updatedScores.forEach((item) => {
      sum += Number(item.score);
    });

    console.log("sum => ", sum);

    if (sum > 100) {
      alert("ຄະເເນນຕ້ອງຫນ້ວຍກວ່າ 100");
    } else {
      let isScoreValid = true;
      for (const grade of data.gradeType) {
        if (parseInt(grade.score) > grade.maxScore) {
          isScoreValid = false;
        }
      }
      if (isScoreValid) {
        await axios
          .post(NEW_API_URL + "score/apart", data, {
            headers: {
              api_key: API_KEY,
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            fetchStudent();
          })
          .catch((error) => {
            // Handle any errors that occurred during the request
            console.error("Error:", error);
          });
      } else {
        alert("ຄະເເນນບໍ່ສາມາດຫລາຍກວ່າ 100");
        fetchStudent();
      }
    }
  };

  const checkValidScore = () => {
    let noScoreCount = 0;
    let _totalscore = 0;

    if (students) {
      students.map((s) => {
        let score = 0;
        s.gradeType.map((grade) => {
          score += grade?.score || 0;
          _totalscore += grade?.score;
        });
        if (score === 0) {
          noScoreCount += 1;
        }
      });
    }
    return _totalscore;
  };

  const sendGrade = async () => {
    const totalScore = checkValidScore();

    if (totalScore === 0) {
      alert("ນັກສຶກສາຕ້ອງມິຄະເເນນກ່ອນຈຶ່ງສາມາດສົ່ງໄດ້");
    } else {
      await axios
        .post(
          NEW_API_URL + "score/teacher/send",
          {
            gradeIdList: gradeIdList,
            filters: filters,
          },
          {
            headers: {
              api_key: API_KEY,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          alert("ສົ້ງຄະເເນນສຳເລັດ");
          fetchStudent();
          // Handle the response data here
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          alert("ສົ້ງຄະເເນນບໍ່ສຳເລັດ");
          console.error("Error:", error);
        });
    }
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  function calculateGrade(score) {
    if (score >= 91) {
      return "A";
    } else if (score >= 81) {
      return "B+";
    } else if (score >= 70) {
      return "B";
    } else if (score >= 65) {
      return "C+";
    } else if (score >= 60) {
      return "C";
    } else if (score >= 55) {
      return "D+";
    } else if (score >= 50) {
      return "D";
    } else {
      return "F";
    }
  }

  const getTotalScore = (data) => {
    if (data.length > 0) {
      let totalScore = 0;
      for (const score of data) {
        totalScore += Number(score.score);
      }
      return totalScore;
    }

    return 0;
  };

  return (
    <div className="px-5 pt-5 bg-white">
      <LoadingDialog
        message={"ກຳລັງໂຫລດ"}
        open={importLoading && !ImportError}
      />
      <StatusDialog open={importSuccess} type={"success"} message={"ສຳເລັດ"} />
      <StatusDialog
        onCancel={() => {
          setIsImportError(false);
        }}
        detail={errorMessage}
        open={ImportError}
        type={"error"}
        message={"ມີບັນຫາໃນການ Import"}
      />

      <div className="row">
        <div className="col">
          <h3 className="text-center">ເພິ່ມຄະເເນນນັກສຶກສາ</h3>
        </div>
      </div>
      <div className="row mb-4 mt-4">
        <div className="col">
          <h5 className="text-center">
            ຄະນະ: {courseClassroom?.faculty?.name}
          </h5>
        </div>
        <div className="col">
          <h5 className="text-center">
            ພາກວິຊາ: {courseClassroom?.department?.name}
          </h5>
        </div>
        <div className="col">
          <h5 className="text-center">ສາຂາ: {courseClassroom?.major?.name}</h5>
        </div>
        <div className="col-1">
          <h5 className="text-center">
            ຫ້ອງຮຽນ: {courseClassroom?.classRoom.name}
          </h5>
        </div>
        <div className="col">
          <h5 className="text-center">ຊື່ວິຊາ: {courseData?.title}</h5>
        </div>
        <div className="col-1">
          <h5 className="text-center">ປີຮຽນ: {courseData?.year}</h5>
        </div>
        <div className="col-1">
          <h5 className="text-center">ພາກຮຽນ: {courseData?.semester}</h5>
        </div>
      </div>
      <div className="row d-flex align-items-center">
        {scoreStatus === "TEACHER_SCORING" && (
          <div className="col-2 mr-3 mb-2">
            <input
              type="file"
              accept=".xlsx"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={onImport}
            />
            <button
              className="btn btn-light shadow-sm"
              onClick={handleButtonClick}
            >
              <span style={{ marginRight: "5px" }}>
                {importLoading ? "ກຳລັງ Import..." : "Import"}
              </span>
              <img
                style={{ transform: "rotate(180deg)" }}
                width="24"
                height="24"
                src="/import.png"
                alt="download--v1"
              />
            </button>
          </div>
        )}

        <div className="col-1 text-start mb-2">
          <button
            className="btn btn-light shadow-sm"
            onClick={() => {
              onExport();
            }}
          >
            <span style={{ marginRight: "5px" }}>Export</span>
            <img width="24" height="24" src="/import.png" alt="download--v1" />
          </button>
        </div>

        <div className="col">
          {scoreStatus === "TEACHER_SCORING" ? (
            <div className="w-100 text-end mb-4 pe-4">
              <button
                onClick={() => {
                  sendGrade();
                }}
                type="button"
                className="btn btn-primary px-5"
              >
                ສົ່ງຄະແນນ
              </button>
            </div>
          ) : scoreStatus === "DEPARTMENT_REJECTED" ? (
            <div className="w-100 text-end mb-4 pe-4">
              <span className="pr-3 text-danger">ຄະເເນນຖືກປະຕິເສດ</span>
              <button
                onClick={() => {
                  sendGrade();
                }}
                type="button"
                className="btn btn-primary px-5"
              >
                ສົ່ງຄະແນນໃຫມ່
              </button>
            </div>
          ) : (
            scoreStatus !== "TEACHER_SCORING" && (
              <div className="w-100 text-end mb-4 pe-4">
                <button
                  disabled
                  type="button"
                  className="btn btn-secondary px-5"
                >
                  ຄະເເນນຖືກສົ່ງແລ້ວ
                </button>
              </div>
            )
          )}
        </div>
      </div>

      <table className="table table-striped">
        <thead className="text-center">
          <tr>
            <th colSpan={1} className="py-3 text-center">
              ລຳດັບ
            </th>
            <th colSpan={2} className="py-3 text-center">
              ລະຫັດນັກສຶກສາ
            </th>
            <th colSpan={3} className="py-3 text-start">
              ຊື່ ແລະ ນາມສະກຸນ
            </th>
            {scoreType &&
              scoreType.map((type, index) => (
                <th key={index} colSpan={2} className="py-3 text-center">
                  {type.name + "   " + (type?.maxScore ?? "")}
                </th>
              ))}
            <th colSpan={2} className="py-3 text-center">
              ຄະເເນນລວມ
            </th>
            <th className="py-3 text-center">ເກຣດ</th>
            <th colSpan={2} className="py-3 text-center">
              ຈັດການ
            </th>
          </tr>
        </thead>
        <tbody>
          {students.length > 0 &&
            students.map((student, index) => (
              <tr
                key={student._id}
                className={index % 2 === 0 ? "even-row" : "odd-row"}
              >
                <td colSpan={1} className="text-center pt-3">
                  {index + 1}
                </td>
                <td colSpan={2} className="text-center pt-3">
                  {student.student.studentNumber}
                </td>

                <td colSpan={3} className="pt-3 px-3 text-start">
                  {student.student.gender === "MALE" ? "ທ້າວ" : "ນາງ"}{" "}
                  {student.student.firstNameL
                    ? `${student.student.firstNameL} ${
                        student.student.lastNameL || student.student.lastNameE
                      }`
                    : `${student.student.firstNameE} ${
                        student.student.lastNameL || student.student.lastNameE
                      }`}
                </td>

                {scoreType &&
                  student.gradeType.map((type, index) => (
                    <td key={index} colSpan={2} className="text-center pt-2">
                      <div className="w-100 text-center d-flex justify-content-center">
                        {student._id === selectedId && (
                          <input
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                student,
                                type._id,
                                type.name,
                                type.maxScore
                              )
                            }
                            type="number"
                            className="form-control mx-auto d-block w-100 text-center"
                            placeholder={type.score}
                            min="0"
                            max={100}
                          />
                        )}
                        {student._id !== selectedId && (
                          <input
                            disabled={true}
                            value={type.score}
                            onChange={(e) =>
                              handleInputChange(e, student, type._id)
                            }
                            type="number"
                            className="form-control mx-auto d-block w-100 text-center"
                            placeholder="ປ້ອນຄະເເນນ"
                            min="0"
                            max="100"
                          />
                        )}
                      </div>
                    </td>
                  ))}

                <td colSpan={2} className="text-center px-3 pt-3 w-100">
                  {getTotalScore(student?.gradeType) ?? student?.totalScore}
                </td>
                <td className="text-center pt-3">
                  {calculateGrade(getTotalScore(student?.gradeType)) ??
                    student?.grade}
                </td>

                <td colSpan={2} className="text-center pt-1">
                  {student._id !== selectedId && (
                    <IconButton
                      disabled={
                        scoreStatus === "TEACHER_SCORING" ||
                        scoreStatus === "DEPARTMENT_REJECTED"
                          ? false
                          : true
                      }
                      onClick={() => onClik(student._id)}
                    >
                      <EditIcon />
                    </IconButton>
                  )}

                  {student._id === selectedId && (
                    <IconButton
                      disabled={
                        scoreStatus === "TEACHER_SCORING" ||
                        scoreStatus === "DEPARTMENT_REJECTED"
                          ? false
                          : true
                      }
                      onClick={async () => {
                        onClik("");
                        const body = {
                          ...reqBody,
                          gradeType: scores,
                        };
                        await saveScore(body, student);
                      }}
                    >
                      <SaveAltIcon />
                    </IconButton>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
