import axios from "axios";

const API_URL = "http://localhost:8000/api/v1/classchat";

export const fetchMessages = async (roomId) => {
  const response = await axios.get(`${API_URL}/${roomId}`);
  return response.data;
};

export const sendMessage = async (data, isFormData = false) => {
  const config = isFormData
    ? { headers: { "Content-Type": "multipart/form-data" } }
    : {};
  try {
    const response = await axios.post(`${API_URL}/send`, data, config);
    return response.data;
  } catch (error) {
    console.error("Error sending message:", error);
    throw error;
  }
};

export const deleteMessage = async (messageId) => {
  const response = await axios.delete(`${API_URL}/${messageId}`);
  return response.data;
};

export const reactToMessage = async (messageId, reaction) => {
  const response = await axios.post(
    `${API_URL}/reaction/${messageId}`,
    reaction
  );
  return response.data;
};

export const updateMessage = async (messageId, text) => {
  const response = await axios.put(`${API_URL}/update/${messageId}`, { text });
  return response.data;
};

// Mark a message as important
export const markImportantMessage = async (messageId) => {
  try {
    const response = await axios.put(`${API_URL}/mark/${messageId}`);
    return response.data;
  } catch (error) {
    console.error("Error marking message as important:", error);
    throw error;
  }
};
