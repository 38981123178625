import React, { useEffect, useState } from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";
import axios from "axios";
import { NEW_API_URL, API_KEY } from "../../common/constraint";
import { USER_KEY } from "../../consts/user";
import {
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Divider,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";

export const CreateFolderDialog = ({ onCancel, onSubmit }) => {
  const user = localStorage.getItem(USER_KEY);
  const userObject = JSON.parse(user);
  const token = userObject?.accessToken;

  const [classList, setClassList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [assessmentYear, setAssessmentYear] = useState("2023 - 2024");
  const [year, selectedYear] = useState("ທັງຫມົດ");
  const [courseId, setCourseId] = useState();
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [folderName, setFolderName] = useState("");
  const [isRootPath, setIsRootParh] = useState(true);

  // id use to fetch data inside folder when its not root path
  const [folderId, setFolderId] = useState("");

  const history = useHistory();
  const currentPath = history.location.pathname;

  useEffect(() => {
    const urlParts = currentPath.split("/");
    const lastParam = urlParts[urlParts.length - 1];

    if (lastParam == 1) {
      setIsRootParh(true);
    } else {
      setFolderId(lastParam);
      setIsRootParh(false);
    }
  }, [currentPath]);

  const fetchCourseData = async (teacherId, year, accessmentYear) => {
    try {
      setSelectedClasses([]);
      setClassList([]);
      const res = await axios.get(
        NEW_API_URL +
          `teacher/course?teacherId=${teacherId}&assessmentYear=${accessmentYear}&year=${year}`,
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCourseList(res.data);
    } catch (err) {
      console.error("Error fetching course data:", err);
    }
  };

  const fetchClassData = async (courseId) => {
    try {
      const res = await axios.get(
        NEW_API_URL +
          `teacher/class?teacherId=${userObject?.data?.id}&courseId=${courseId}`,
        {
          headers: {
            api_key: API_KEY,
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClassList(res.data);
    } catch (err) {
      console.error("Error fetching class data:", err);
    }
  };

  const handleYearChange = (event) => {
    selectedYear(event.target.value);
  };

  const handleAssessmentYearChange = (event) => {
    setAssessmentYear(event.target.value);
  };

  const handleCourseChange = (event) => {
    setCourseId(event.target.value);
  };

  const handleFolderNameChange = (event) => {
    setFolderName(event.target.value);
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
  };

  const handleClassChange = (_classId) => {
    setSelectedClasses((prevSelectedClasses) => {
      const isSelected = prevSelectedClasses.includes(_classId);
      if (isSelected) {
        // If already selected, remove the class from the array
        return prevSelectedClasses.filter((id) => id !== _classId);
      } else {
        // If not selected, add the class to the array
        return [...prevSelectedClasses, _classId];
      }
    });
  };

  const createFolder = async () => {
    try {
      let formData = {};
      formData = {
        course: courseId,
        classRoom: selectedClasses,
        teacher: userObject?.data?.id,
        folderName: folderName,
      };
      if (isRootPath === false) {
        formData.parentId = folderId;
      }

      await axios.post(NEW_API_URL + "filefolder", formData, {
        headers: {
          api_key: API_KEY,
          Authorization: `Bearer ${token}`,
        },
      });

      // argument is indicate that successs or not
      // if success then true failed the false
      onSubmit(true);
    } catch (err) {
      onSubmit(false);
    }
  };

  useEffect(() => {
    if (userObject) {
      fetchCourseData(userObject?.data?.id, year, assessmentYear);
    }
  }, [assessmentYear, year]);

  useEffect(() => {
    if (courseId) {
      fetchClassData(courseId);
    }
  }, [courseId]);

  return (
    <Dialog fullWidth open={true}>
      <DialogTitle>ຊື່ Folder</DialogTitle>
      <DialogContent>
        <div className="row">
          <div className="col-12 mb-3">
            <input
              onChange={handleFolderNameChange}
              className="newinput"
              type="text"
            />
          </div>
          <Divider />
          {isRootPath === true && (
            <>
              <div className="col-12 mt-3">
                <Typography variant="h6">ເລຶອກວິຊາ</Typography>
              </div>
              <div className="col-6 mt-3">
                <InputLabel id="select-label">ສົກຮຽນ</InputLabel>
                <Select
                  defaultValue={""}
                  fullWidth
                  labelId="select-label"
                  id="select"
                  value={assessmentYear}
                  onChange={handleAssessmentYearChange}
                >
                  <MenuItem value={"2019 - 2020"}>2019 - 2020</MenuItem>
                  <MenuItem value={"2020 - 2021"}>2020 - 2021</MenuItem>
                  <MenuItem value={"2022 - 2023"}>2022 - 2023</MenuItem>
                  <MenuItem value={"2023 - 2024"}>2023 - 2024</MenuItem>
                </Select>
              </div>
              <div className="col-6 mt-3">
                <InputLabel id="select-label">ປີ</InputLabel>
                <Select
                  defaultValue={"ທັງຫມົດ"}
                  fullWidth
                  labelId="select-label"
                  id="select"
                  value={year}
                  onChange={handleYearChange}
                >
                  <MenuItem value={"ທັງຫມົດ"}>ທັງຫມົດ</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={6}>6</MenuItem>
                </Select>
              </div>
              <div className="col-12 mt-3 mb-3">
                <InputLabel id="select-label">ວິຊາສອນ</InputLabel>
                <Select
                  defaultValue={1}
                  fullWidth
                  labelId="select-label"
                  id="select"
                  value={courseId}
                  onChange={handleCourseChange}
                >
                  {courseList.map((course) => (
                    <MenuItem key={course?._id} value={course?._id}>
                      {course?.title}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </>
          )}

          <Divider />
          {classList.length > 0 && (
            <div className="col-12 mt-3">
              <Typography variant="h6">ເລືອກຫ້ອງທີ່ສາມາດເຂົ້າເຖີງ</Typography>
            </div>
          )}

          <div className="col-12">
            <FormGroup>
              {classList.map((_class) => (
                <FormControlLabel
                  key={_class?.id}
                  control={
                    <Checkbox
                      value={_class?.id}
                      checked={selectedClasses.includes(_class?.id)}
                      onChange={() => handleClassChange(_class?.id)}
                    />
                  }
                  label={_class?.name}
                />
              ))}
            </FormGroup>
          </div>
          <div className="col-12 mt-3 text-center my-5">
            {isRootPath ? (
              <Button
                disabled={classList.length > 0 ? false : true}
                onClick={createFolder}
                className="mx-3 px-3"
                variant="contained"
                color="primary"
              >
                ບັນທຶກ
              </Button>
            ) : (
              <Button
                disabled={folderName ? false : true}
                onClick={createFolder}
                className="mx-3 px-3"
                variant="contained"
                color="primary"
              >
                ບັນທຶກ
              </Button>
            )}
            <Button
              onClick={handleCancel}
              className="px-3"
              variant="contained"
              color="secondary"
            >
              ຍົກເລີກ
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreateFolderDialog;
