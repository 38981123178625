import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Switch,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  School as SchoolIcon,
  AccountCircle as AccountCircleIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  Event as EventIcon,
  AccessTime as AccessTimeIcon,
} from "@mui/icons-material";
import { fetchGet } from "../../common/fetchAPI";
import { USER_KEY } from "../../consts/user";

const AttendanceReport = ({ courseId }) => {
  const [attendanceList, setAttendanceList] = useState([]);
  const [selectedAttendance, setSelectedAttendance] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const fetchReport = async () => {
    try {
      const user = localStorage.getItem(USER_KEY);
      const userObject = user ? JSON.parse(user) : null;

      if (!userObject || !userObject.data || !userObject.data.id) {
        console.warn("User data is missing or incomplete");
        return;
      }

      const response = await fetchGet(
        `/attendance?teacherId=${userObject.data.id}&courseId=${courseId}`
      );
      setAttendanceList(response || []);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
      setAttendanceList([]);
    }
  };

  useEffect(() => {
    if (courseId) {
      fetchReport();
    }
  }, [courseId]);

  const handleOpenDialog = (attendance) => {
    setSelectedAttendance(attendance);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedAttendance(null);
  };

  const handleStatusToggle = (studentId) => {
    if (selectedAttendance && selectedAttendance.records) {
      const updatedRecords = selectedAttendance.records.map((record) =>
        record.student && record.student._id === studentId
          ? {
              ...record,
              status: record.status === "present" ? "ບໍ່ມາ" : "ມາ",
            }
          : record
      );
      setSelectedAttendance({
        ...selectedAttendance,
        records: updatedRecords,
      });
    }
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Grid container spacing={3}>
        {attendanceList.length > 0 ? (
          attendanceList.map((attendance) => {
            const totalStudents = attendance.records
              ? attendance.records.length
              : 0;
            const presentCount = attendance.records
              ? attendance.records.filter(
                  (record) => record.status === "present"
                ).length
              : 0;
            const absentCount = totalStudents - presentCount;

            return (
              <Grid item xs={12} sm={6} md={4} key={attendance._id}>
                <Card
                  sx={{
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                    borderRadius: 2,
                    padding: 2,
                    position: "relative",
                  }}
                >
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 700, color: "text.primary", mb: 1 }}
                    >
                      {attendance.date
                        ? new Date(attendance.date).toLocaleDateString()
                        : "N/A"}
                    </Typography>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>
                        <EventIcon sx={{ color: "text.secondary" }} />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="body2"
                          sx={{ color: "text.secondary", fontWeight: 500 }}
                        >
                          {attendance.day || "N/A"}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <AccessTimeIcon sx={{ color: "text.secondary" }} />
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" color="text.secondary">
                          {attendance.time || "N/A"}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "green",
                          }}
                        >
                          <CheckCircleIcon sx={{ mr: 1 }} />
                          <Typography variant="body2">
                            ຈຳນວນມາ: {presentCount}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "red",
                          }}
                        >
                          <CancelIcon sx={{ mr: 1 }} />
                          <Typography variant="body2">
                            ຈຳນວນບໍ່ມາ: {absentCount}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                      onClick={() => handleOpenDialog(attendance)}
                    >
                      ລາຍລະອຽດ
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Box
            sx={{
              width: "100%",
              height: "80vh",
              alignContent: "center",
              justifyItems: "center",
            }}
          >
            <Typography variant="body1" color="text.secondary">
              No attendance data available.
            </Typography>
          </Box>
        )}
      </Grid>
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md">
        <DialogContent>
          {selectedAttendance ? (
            <Box>
              <Typography variant="subtitle1" sx={{ mb: 2 }}>
                ວັນທີ: {selectedAttendance.date || "N/A"}
              </Typography>
              <Grid container spacing={2}>
                {selectedAttendance.records &&
                  selectedAttendance.records.map((record) => (
                    <Grid
                      key={record._id}
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        borderBottom: "1px solid #e0e0e0",
                        paddingBottom: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      <Avatar sx={{ mr: 2 }}>
                        <AccountCircleIcon />
                      </Avatar>
                      <Typography variant="body1" sx={{ flexGrow: 1 }}>
                        {record.student?.firstNameE || "Unknown"}{" "}
                        {record.student?.lastNameE || "Unknown"} (
                        {record.student?.gender || "N/A"})
                      </Typography>
                      <Switch
                        checked={record.status === "present"}
                        onChange={() => handleStatusToggle(record.student._id)}
                        color="primary"
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          color: record.status === "present" ? "green" : "red",
                          fontWeight: "bold",
                          ml: 2,
                        }}
                      >
                        {record.status
                          ? record.status.charAt(0).toUpperCase() +
                            record.status.slice(1)
                          : "N/A"}
                      </Typography>
                    </Grid>
                  ))}
              </Grid>
            </Box>
          ) : (
            <Typography>No details available.</Typography>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default AttendanceReport;
