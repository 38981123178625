import React, { useState } from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import Consts from "../consts";
import Routers from "../consts/routes";
import { USER_KEY } from "../consts/user";
import "./sideNav.css";
import SideNav, { NavItem, NavIcon, NavText } from "@trendmicro/react-sidenav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CustomSideNav({ location, history }) {
  const [selectStatus, setSelectStatus] = useState(
    location.pathname.split("/")[1].split("-")[0]
  );

  const _goPage = (path) => {
    history.push(path);
  };
  const user = JSON.parse(localStorage.getItem(USER_KEY));

  React.useEffect(() => {
    if (!user) {
      history.push("/");
    }
  }, []);
  return (
    <SideNav
      onSelect={(selected) => {
        setSelectStatus(selected.split("-")[0]);
        if (selected === "grades-list") {
          selected = selected + Routers.PAGE_GINATION;
        } else if (selected === "add-grade-for-student") {
          selected = selected + "/" + 1;
        } else if (selected === "notice-list") {
          selected = selected + "/" + 1;
        } else if (selected === "course-list") {
          selected = selected + "/" + 1;
        } else if (selected === "emerald") {
          selected = selected + "/";
        } else if (selected === "document-list") {
          selected = selected + Routers.PAGE_GINATION;
        } else if (selected === "upgrades-list") {
          selected = selected + "/" + 1;
        } else if (selected === "scopus") {
          selected = selected + "/";
        } else if (selected === "attendance") {
          selected = selected + "/";
        }
        const to = "/" + selected;
        if (location.pathname !== to) {
          history.push(to);
          // window.location.reload(true)
        }
      }}
      style={{
        position: "fixed",
        backgroundColor: "#F5F5F5",
        zIndex: 10000,
      }}
    >
      <SideNav.Toggle />
      <SideNav.Nav defaultSelected={location.pathname.split("/")[1]}>
        <NavItem
          eventKey="course-list"
          active={selectStatus === "course" ? true : false}
          style={{
            borderLeft:
              selectStatus === "course"
                ? "6px solid #7BB500"
                : "6px solid #fff",
          }}
        >
          <NavIcon>
            <i className="fa fa-bookmark" style={{ fontSize: "1.75em" }} />
          </NavIcon>
          <NavText style={{ color: Consts.PRIMARY_COLOR }}>ວິຊາທີ່ສອນ</NavText>
        </NavItem>

        <NavItem
          eventKey="document-list"
          active={selectStatus === "document" ? true : false}
          style={{
            borderLeft:
              selectStatus === "document"
                ? "6px solid #7BB500"
                : "6px solid #fff",
          }}
        >
          <NavIcon>
            <i className="fa fa-folder" style={{ fontSize: "1.75em" }} />
          </NavIcon>
          <NavText style={{ color: Consts.PRIMARY_COLOR }}>ເອກະສານ</NavText>
        </NavItem>

        <NavItem
          eventKey="emerald"
          active={selectStatus === "emerald" ? true : false}
          style={{
            borderLeft:
              selectStatus === "emerald"
                ? "6px solid #7BB500"
                : "6px solid #fff",
          }}
        >
          <NavIcon>
            <i className="fa fa-book-reader" style={{ fontSize: "1.75em" }} />
          </NavIcon>
          <NavText style={{ color: Consts.PRIMARY_COLOR }}>
            ຫ້ອງສະໝຸດທັນສະໄໝ
          </NavText>
        </NavItem>

        <NavItem
          eventKey="notice-list"
          active={selectStatus === "notice" ? true : false}
          style={{
            borderLeft:
              selectStatus === "notice"
                ? "6px solid #7BB500"
                : "6px solid #fff",
          }}
        >
          <NavIcon>
            <i
              className="fa fa-bullhorn"
              aria-hidden="true"
              style={{ fontSize: "1.75em" }}
            />
          </NavIcon>
          <NavText style={{ color: Consts.PRIMARY_COLOR }}>
            ແຈ້ງການ ແລະ ລະບຽບ
          </NavText>
        </NavItem>
        <NavItem
          eventKey="grades-list"
          active={selectStatus === "grades" ? true : false}
          style={{
            borderLeft:
              selectStatus === "grades"
                ? "6px solid #7BB500"
                : "6px solid #fff",
          }}
        >
          <NavIcon>
            <i
              className="fas fa-clipboard-list"
              aria-hidden="true"
              style={{ fontSize: "1.75em" }}
            />
          </NavIcon>
          <NavText style={{ color: Consts.PRIMARY_COLOR }}>
            ການເບິ່ງຄະແນນ
          </NavText>
        </NavItem>
        <NavItem
          eventKey="upgrades-list"
          active={selectStatus === "upgrades" ? true : false}
          style={{
            borderLeft:
              selectStatus === "upgrades"
                ? "6px solid #7BB500"
                : "6px solid #fff",
          }}
        >
          <NavIcon>
            <i
              className="fas fa-arrow-alt-circle-up"
              aria-hidden="true"
              style={{ fontSize: "1.75em" }}
            />
          </NavIcon>
          <NavText style={{ color: Consts.PRIMARY_COLOR }}>
            ລາຍການອັບເກຣດ
          </NavText>
        </NavItem>

        <NavItem
          eventKey={"elearning"}
          active={selectStatus === "elearning"}
          style={{
            borderLeft:
              selectStatus === "elearning"
                ? "6px solid #7BB500"
                : "6px solid #fff",
          }}
        >
          <NavIcon>
            <i
              className="fa-solid fa-chalkboard-teacher"
              aria-hidden="true"
              style={{ fontSize: "1.75em" }}
            />
          </NavIcon>
          <NavText style={{ color: Consts.PRIMARY_COLOR }}>E-Learning</NavText>
        </NavItem>

        <NavItem
          eventKey={"attendance"}
          active={selectStatus === "attendance"}
          style={{
            borderLeft:
              selectStatus === "attendance"
                ? "6px solid #7BB500"
                : "6px solid #fff",
          }}
        >
          <NavIcon>
            <i
              className="fa-solid fa-user-check"
              aria-hidden="true"
              style={{ fontSize: "1.75em" }}
            />
          </NavIcon>
          <NavText style={{ color: Consts.PRIMARY_COLOR }}>ຕິດຕາມ</NavText>
        </NavItem>

        <NavItem
          eventKey={"coursefile/1"}
          active={selectStatus === "coursefile"}
          style={{
            borderLeft:
              selectStatus === "coursefile"
                ? "6px solid #7BB500"
                : "6px solid #fff",
          }}
        >
          <NavIcon>
            <i
              className="fa-solid fa-book"
              aria-hidden="true"
              style={{ fontSize: "1.75em" }}
            />
          </NavIcon>
          <NavText style={{ color: Consts.PRIMARY_COLOR }}>
            ເອກະສານບົດສອນ
          </NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
}

export default CustomSideNav;
