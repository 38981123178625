import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import ReplyIcon from "@mui/icons-material/Reply";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import List from "@mui/material/List";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
} from "../../../common/fetchAPI";
import { USER_KEY } from "../../../consts/user";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "hidden",
};

const userListContainerStyle = {
  maxHeight: "700px",
  overflowY: "auto",
  padding: "8px",
};

const commentContainerStyle = {
  display: "flex",
  flexDirection: "column",
  borderRadius: "8px",
  padding: "12px",
  width: "100%",
};

const commentHeaderStyle = {
  display: "flex",
  alignItems: "center",
  padding: "10px",
  cursor: "pointer",
  backgroundColor: "#fafafa",
  borderRadius: "10px",
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "#f0f0f0",
  },
};

const commentBodyStyle = {
  display: "flex",
  gap: "10px",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  backgroundColor: "white",
};

const commentTextStyle = {
  backgroundColor: "#f0f0f0",
  padding: "15px",
  borderRadius: "8px",
  width: "auto",
};

const replyContainerStyle = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "40px",
  marginTop: "8px",
  alignItems: "flex-end",
};

const replyTextStyle = {
  backgroundColor: "#caf0f8",
  padding: "15px",
  borderRadius: "8px",
  marginBottom: "8px",
  width: "auto",
  maxWidth: "400px", // Limit the width
  wordWrap: "break-word", // Allow text wrapping
};

const highlightedReplyTextStyle = {
  backgroundColor: "white",
  padding: "15px",
  borderRadius: "8px",
  marginBottom: "8px",
  width: "auto",
  maxWidth: "400px", // Limit the width
  wordWrap: "break-word", // Allow text wrapping
};

const errorStyle = {
  color: "red",
  marginTop: "16px",
};

export default function CommentModal({ chapterId, open, onClose }) {
  const [reply, setReply] = useState("");
  const [expandedUser, setExpandedUser] = useState(null);
  const [commentGrouped, setCommentgroup] = useState([]);
  const [replyingCommentId, setReplyingCommentId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuReplyId, setMenuReplyId] = useState(null);

  // To store the updated reply text
  const [editingReplyId, setEditingReplyId] = useState(null);
  const [updatedReply, setUpdatedReply] = useState("");

  const handleMenuClick = (event, replyId) => {
    setAnchorEl(event.currentTarget);
    setMenuReplyId(replyId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuReplyId(null);
  };

  const handleDeleteReply = async () => {
    await fetchDelete("/treply/" + menuReplyId);
    handleMenuClose();
    fetchComments();
  };

  const handleEditReply = async (replyId, currentText) => {
    setEditingReplyId(replyId);
    // setUpdatedReply(currentText); // Set current text in the input
    // handleMenuClose();
    // await fetchPut("/treply/" + replyId, {
    //   title: currentText,
    // });
    // fetchComments();
  };

  const handleUpdateReply = async (replyId) => {
    await fetchPut("/treply/" + replyId, {
      title: updatedReply,
    });
    setEditingReplyId(null);
    setUpdatedReply("");
    fetchComments();
  };

  const fetchComments = async () => {
    setLoading(true);
    setError(null);
    try {
      const _comments = await fetchGet("/tcomment/chapter/" + chapterId);
      const groupedComments = _comments.reduce((acc, comment) => {
        if (!acc[comment.student._id]) {
          acc[comment.student._id] = {
            student: comment.student,
            comments: [],
          };
        }
        acc[comment.student._id].comments.push(comment);
        return acc;
      }, {});

      setCommentgroup(groupedComments);
    } catch (err) {
      setError("Failed to load comments. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchComments();
    }
  }, [chapterId, open]);

  const handleReplyChange = (event) => {
    setReply(event.target.value);
  };

  const handleReplySubmit = async (commentId) => {
    try {
      const user = localStorage.getItem(USER_KEY);
      const userObject = JSON.parse(user);

      await fetchPost("/treply", {
        teacher: userObject?.data?.id,
        title: reply,
        comment: commentId,
      });
      setReply("");
      setReplyingCommentId(null);
      fetchComments();
    } catch (err) {}
  };

  const handleUserClick = (studentId) => {
    setExpandedUser(expandedUser === studentId ? null : studentId);
  };

  const handleReplyClick = (commentId) => {
    setReplyingCommentId(commentId);
  };

  const handleCancelReply = () => {
    setReplyingCommentId(null);
    setReply("");
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography sx={{ mb: 2 }} variant="h6" component="h2">
            ລາຍກາຮຄຳຄິດເຫັນຈາກນັກສຶກສາ
          </Typography>
          <IconButton
            edge="end"
            aria-label="close"
            onClick={onClose}
            sx={{ ml: 1 }}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 200,
            }}
          >
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography sx={errorStyle}>{error}</Typography>
        ) : (
          <Box sx={userListContainerStyle}>
            {Object.keys(commentGrouped).map((studentId) => {
              const { student, comments } = commentGrouped[studentId];
              return (
                <Box key={studentId} sx={{ mb: 0 }}>
                  <Box
                    sx={commentHeaderStyle}
                    onClick={() => handleUserClick(studentId)}
                  >
                    <Avatar
                      src={`path/to/images/${student?.image?.url}`}
                      alt={student.firstNameL}
                      sx={{ mr: 2 }}
                    />
                    <Typography variant="subtitle1">
                      {`${student.firstNameL} ${student.lastNameL}`}
                    </Typography>
                  </Box>
                  <Collapse in={expandedUser === studentId}>
                    <List>
                      {comments.map((comment) => (
                        <Box key={comment._id} sx={{ mb: 2 }}>
                          <Box sx={commentContainerStyle}>
                            <Box sx={commentBodyStyle}>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={commentTextStyle}
                                >
                                  {comment.title}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {new Date(
                                    comment.updatedAt
                                  ).toLocaleTimeString() +
                                    " " +
                                    new Date(
                                      comment.updatedAt
                                    ).toLocaleDateString()}
                                </Typography>
                              </Box>
                              <IconButton
                                edge="end"
                                aria-label="reply"
                                onClick={() => handleReplyClick(comment._id)}
                                sx={{ ml: 1, alignSelf: "center" }}
                              >
                                <ReplyIcon />
                              </IconButton>
                            </Box>
                            {comment._id === replyingCommentId && (
                              <Box sx={{ mt: 2 }}>
                                <TextField
                                  fullWidth
                                  multiline
                                  rows={4}
                                  value={reply}
                                  onChange={handleReplyChange}
                                  placeholder="Reply to this comment..."
                                  sx={{
                                    bgcolor: "background.default", // Default background color for MUI TextField
                                  }}
                                />
                                <Box sx={{ mt: 1, display: "flex", gap: 1 }}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                      handleReplySubmit(comment._id)
                                    }
                                  >
                                    ຕອບກັບ
                                  </Button>
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={handleCancelReply}
                                  >
                                    ຍົກເລີກ
                                  </Button>
                                </Box>
                              </Box>
                            )}

                            <Box sx={replyContainerStyle}>
                              {comment.replies.map((reply) => (
                                <Box
                                  key={reply._id}
                                  sx={{
                                    position: "relative",
                                    width: "100%",
                                  }}
                                >
                                  <Typography
                                    variant="body2"
                                    sx={
                                      editingReplyId === reply._id
                                        ? highlightedReplyTextStyle
                                        : replyTextStyle
                                    }
                                  >
                                    {editingReplyId === reply._id ? (
                                      <TextField
                                        placeholder="ເພີ່ມຄຳຕອບໃຫມ່ທີ່ຕ້ອງການເເກ້ໄຂ"
                                        fullWidth
                                        value={updatedReply}
                                        onChange={(e) =>
                                          setUpdatedReply(e.target.value)
                                        }
                                        multiline
                                        rows={4}
                                        sx={{
                                          bgcolor: "background.default", // Default background color for MUI TextField
                                        }}
                                      />
                                    ) : (
                                      reply.title
                                    )}
                                  </Typography>
                                  <Box
                                    sx={{
                                      paddingRight: "40px",
                                      display: "flex",
                                      flexDirection: "row",
                                      alignContent: "flex-end",
                                      width: "100%",
                                      alignItems: "end",
                                      justifyContent: "right",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                      }}
                                    >
                                      {new Date(
                                        reply.updatedAt
                                      ).toLocaleTimeString() +
                                        " " +
                                        new Date(
                                          reply.updatedAt
                                        ).toLocaleDateString()}
                                    </Typography>
                                  </Box>

                                  {editingReplyId === reply._id ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        gap: 2,
                                        paddingLeft: "15px",
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() =>
                                          handleUpdateReply(reply._id)
                                        }
                                      >
                                        ບັນທຶກ
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => {
                                          setEditingReplyId(null);
                                          setUpdatedReply("");
                                        }}
                                      >
                                        ຍົກເລີກ
                                      </Button>
                                    </Box>
                                  ) : (
                                    <IconButton
                                      edge="end"
                                      aria-label="more"
                                      onClick={(e) =>
                                        handleMenuClick(e, reply._id)
                                      }
                                      sx={{
                                        position: "absolute",
                                        top: 0,
                                        right: -25,
                                      }}
                                    >
                                      <MoreVertIcon />
                                    </IconButton>
                                  )}
                                </Box>
                              ))}
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </List>
                  </Collapse>
                </Box>
              );
            })}
          </Box>
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() =>
              handleEditReply(menuReplyId, commentGrouped[menuReplyId]?.title)
            }
          >
            <EditIcon sx={{ mr: 1 }} /> ແກ້ໄຂ
          </MenuItem>
          <MenuItem onClick={handleDeleteReply}>
            <DeleteIcon sx={{ mr: 1 }} /> ລົບ
          </MenuItem>
        </Menu>
      </Box>
    </Modal>
  );
}
