import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";

import Login from "./pages/login/Login";
import Routers from "./consts/routes";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

// Course
import CourseList from "./pages/courseList/CourseList";
import CourseListAll from "./pages/courseList/CourseListAll";
import CourseAdd from "./pages/courseAdd/CourseAdd";
import CourseDetail from "./pages/courseDetail/CourseDetail";
import CourseEdit from "./pages/courseEdit/CourseEdit";
import CourseDocList from "./pages/courseDocList/CourseDocList";
import AddGradeForStudent from "./pages/gradesList/AddGradeForStudent";

import CourseFile from "./pages/courseFile";
// import ModalUserProfile from './ModalUserProfile'

// Docs
import DocList from "./pages/docList/DocList";
import DocDetail from "./pages/docList/DocDetail";

// Notice
import NoticeList from "./pages/notice/NoticeList";
import NoticeDetail from "./pages/notice/NoticeDetail";

// Upgrade
import UpgradeList from "./pages/upgrade/UpgradeList";

// CustomSideNav
import { CustomSideNav } from "./common";
import NavBar from "./common/NavBar";

// GiveGradeForStudent
import GradesAndUpGrade from "./pages/giveGradeForStudent";
import Emerald from "./pages/emerald/Emerald";
// REGISTER STUDENT

import RegisterList from "./pages/register/RegisterList";
import CourseLists from "./pages/courseList/CourseLists";
import RegisterAll from "./pages/register/RegisterAll";
import UpgradesList from "./pages/gradesList/UpgradesList";
import GradesList from "./pages/gradesList/GradesList";
import StudentListForGrade from "./pages/gradesList/StudentListForGrade";
import { GiveGradeMainPage } from "./pages/V2/giveGradeForStudentV2";
import { ScopusPage } from "./pages/V2/scopus";
import ExcelDisplay from "./pages/courseFile/ExcelDisplay";
import ImageDisplay from "./pages/courseFile/ImageDisplay";
import PDFVDisplay from "./pages/courseFile/PDFDisplay";
import ELearningPage from "./pages/e-learning";
import TCourseDetail from "./pages/e-learning/courseDetail/CourseDetail";
import ExamAdd from "./pages/e-learning/courseDetail/ExamAdd";
import SubmitStudentExam from "./pages/e-learning/courseDetail/submitStudentExam";
import StudentSubmitList from "./pages/e-learning/courseDetail/ExamSubmitList";
import Attendance from "./pages/attendance";
import AttendanceManagement from "./pages/attendance";
import HomeworkAdd from "./pages/e-learning/courseDetail/HomeWorkAdd";
import Instructor from "./pages/e-learning/communities/instructor";
import InstructorProfileDetail from "./pages/e-learning/communities/insctructionDetail";
import HomeworkSubmit from "./pages/e-learning/courseDetail/HomeWorkSubmmit";

const Main = styled.main`
  /* position: relative; */
  overflow: hidden;
  transition: all 0.15s;
  padding: 0 20px;
  margin-left: ${(props) => (props.expanded ? 240 : 64)}px;
`;
function Routes() {
  return (
    <Router>
      <Switch>
        {/* Before login routes */}
        <PublicRoute exact path="/" component={Login} />

        {/* After login routes (has SideNav and NavBar) */}
        <Route
          render={({ location, history }) => (
            <React.Fragment>
              {/* sidenav */}
              <CustomSideNav location={location} history={history} />
              <Main>
                {/* navbar */}
                <NavBar />
                {/* Contents */}
                <div
                  style={{
                    marginTop: 60,
                    backgroundColor: "#eee",
                    minHeight: "100vh",
                  }}
                >
                  <PrivateRoute
                    path="/course-list/:page"
                    exact
                    component={(props) => <CourseList />}
                  />
                  <PrivateRoute
                    path={
                      Routers.COURSE_LIST_ALL +
                      "/id/:id/limit/:limit/skip/:skip"
                    }
                    exact
                    component={(props) => <CourseListAll />}
                  />
                  <PrivateRoute
                    path="/course-add"
                    component={(props) => <CourseAdd />}
                  />
                  <PrivateRoute
                    path="/course-detail/id/:id"
                    exact
                    component={(props) => <CourseDetail />}
                  />
                  <PrivateRoute
                    path="/course-edit/:id"
                    component={(props) => <CourseEdit />}
                  />
                  <PrivateRoute
                    path="/course-doc-list/:id"
                    component={(props) => <CourseDocList />}
                  />

                  {/* doc PrivateRoutes */}
                  <PrivateRoute
                    path={Routers.DOCUMENT_LIST + "/limit/:limit/skip/:skip/"}
                    component={(props) => <DocList />}
                  />
                  <PrivateRoute
                    path="/documents/:docId"
                    component={(props) => <DocDetail />}
                  />
                  <PrivateRoute
                    path="/notice-list/:page"
                    component={(props) => <NoticeList />}
                  />
                  <PrivateRoute
                    path="/notice-detail/:id/:type"
                    exact
                    component={(props) => <NoticeDetail />}
                  />
                  <PrivateRoute
                    path={Routers.GRADE_LIST + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <GradesList />}
                  />
                  <PrivateRoute
                    path={Routers.UPGRADE_LIST + "/limit/:limit/skip/:skip"}
                    exact
                    component={(props) => <UpgradesList />}
                  />
                  <PrivateRoute
                    path={Routers.STUDENT_FOR_GRADE}
                    exact
                    component={(props) => <StudentListForGrade />}
                  />
                  <PrivateRoute
                    path="/add-grade-for-student/:page"
                    exact
                    component={(props) => <AddGradeForStudent />}
                  />

                  <PrivateRoute
                    path="/upgrades-list/:page"
                    exact
                    component={(props) => <UpgradeList />}
                  />

                  <PrivateRoute
                    path="/giveGradeForStudent/:page"
                    exact
                    component={(props) => <GradesAndUpGrade />}
                  />

                  <PrivateRoute
                    path="/giveGradeForStudentV2/"
                    exact
                    component={(props) => <GiveGradeMainPage />}
                  />
                  <PrivateRoute
                    path="/register-list/id/:id"
                    exact
                    component={(props) => <RegisterList />}
                  />
                  <PrivateRoute
                    path="/course-lists/:page"
                    exact
                    component={(props) => <CourseLists />}
                  />
                  <PrivateRoute
                    path={
                      Routers.REGISTER_ALL + "/id/:id/limit/:limit/skip/:skip"
                    }
                    exact
                    component={(props) => <RegisterAll />}
                  />
                  <PrivateRoute
                    path="/emerald"
                    exact
                    component={() => <Emerald />}
                  />
                  <PrivateRoute
                    path="/view/excel"
                    exact
                    component={() => <ExcelDisplay />}
                  />
                  <PrivateRoute
                    path="/view/image"
                    exact
                    component={() => <ImageDisplay />}
                  />
                  <PrivateRoute
                    path="/view/pdf"
                    exact
                    component={() => <PDFVDisplay />}
                  />
                  <PrivateRoute
                    path="/scopus"
                    exact
                    component={() => <ScopusPage />}
                  />
                  <PrivateRoute
                    path="/coursefile/*"
                    exact
                    component={() => <CourseFile />}
                  />
                  <PrivateRoute
                    path="/elearning"
                    exact
                    component={() => <ELearningPage />}
                  />
                  <PrivateRoute
                    path="/elearning/instructor/edit/:id"
                    exact
                    component={() => <Instructor />}
                  />
                  <PrivateRoute
                    path="/elearning/examadd/:id"
                    exact
                    component={() => <ExamAdd />}
                  />
                  <PrivateRoute
                    path="/elearning/homeworkadd/:id"
                    exact
                    component={() => <HomeworkAdd />}
                  />
                  <PrivateRoute
                    path="/elearning/coursedetail/:id"
                    exact
                    component={() => <TCourseDetail />}
                  />
                  <PrivateRoute
                    path="/elearning/examsubmit/:id"
                    exact
                    component={() => <SubmitStudentExam />}
                  />
                  <PrivateRoute
                    path="/elearning/homeworksubmit/:id"
                    exact
                    component={() => <HomeworkSubmit />}
                  />
                  <PrivateRoute
                    path="/elearning/examsubmit/"
                    exact
                    component={() => <StudentSubmitList />}
                  />
                  <PrivateRoute
                    path="/attendance"
                    exact
                    component={() => <AttendanceManagement />}
                  />
                </div>
              </Main>
            </React.Fragment>
          )}
        />
      </Switch>
      {/* <ModalUserProfile 
        showUserProfile={showUserProfile}
        _handleShowUserProfile={_handleShowUserProfile}
        /> */}
    </Router>
  );
}

export default Routes;
