import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  School as SchoolIcon,
  AccountCircle as AccountCircleIcon,
  MoreVert as MoreVertIcon,
  Close as CloseIcon,
  Circle as CircleIcon,
} from "@mui/icons-material";
import useReactRouter from "use-react-router";
import { useDispatch } from "react-redux";
import { setCourseDetail } from "../../redux/tCourseSlice";
import ConfirmModal from "../../common/ConfirmDialog";

const TCourseCard = ({
  title,
  description,
  courseName,
  students,
  onDelete,
  id,
}) => {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("all");
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  const activeStudents = students.filter((student) => student.isActive);
  const filteredStudents = filter === "active" ? activeStudents : students;

  const { history } = useReactRouter();

  const [showConfirm, setShowConfirm] = useState(false);

  return (
    <Card
      sx={{
        maxWidth: 400,
        mx: "auto",
        width: 350,
        height: 220,
        position: "relative",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Modern soft shadow
        borderRadius: 1, // Slightly rounded corners
      }}
    >
      <ConfirmModal
        onSubmit={() => {
          setAnchorEl(null);
          setShowConfirm(false);
          onDelete(id);
        }}
        onCancel={() => setShowConfirm(false)}
        message={"ເມື່ອທ່ານກົດຢືນຢັນຂໍ້ມຸນບົດສອນຕ່າງໆຈະຖືກລົບອອກໄປຫມົດ"}
        open={showConfirm}
        title={"ລົບວິຊາສອນ"}
      />
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleMenuOpen}
        sx={{ position: "absolute", top: 15, right: 8 }}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={() => setShowConfirm(true)}>ລົບ</MenuItem>
      </Menu>

      <CardContent sx={{ py: 2 }}>
        <Typography
          variant="h5"
          component="div"
          sx={{ fontWeight: 600 }}
          gutterBottom
        >
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          {description}
        </Typography>
        <Grid container spacing={1} alignItems="center" sx={{ mt: 1 }}>
          <Grid item>
            <Avatar
              sx={{ width: 30, height: 30, backgroundColor: "primary.main" }}
            >
              <SchoolIcon sx={{ fontSize: 20, color: "white" }} />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
              ວິຊາ: {courseName}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center" sx={{ mt: 1 }}>
          <Grid item>
            <Avatar
              sx={{ width: 30, height: 30, backgroundColor: "secondary.main" }}
            >
              <AccountCircleIcon sx={{ fontSize: 20, color: "white" }} />
            </Avatar>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              sx={{ cursor: "pointer", fontWeight: 500 }}
              onClick={handleOpen}
            >
              ຈຳນວນນັກສຶກສາ:{" "}
              <Typography
                variant="subtitle1"
                component="span"
                sx={{ color: "primary.main", fontWeight: 600 }}
              >
                {students.length}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>

      <Box sx={{ p: 1 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            dispatch(
              setCourseDetail({
                courseId: id,
                tabIndex: 0,
              })
            );
            history.push("/elearning/coursedetail/" + id);
          }}
          sx={{ position: "absolute", bottom: 12, right: 12, borderRadius: 3 }}
        >
          ລາຍລະອຽດ
        </Button>
      </Box>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>
          ລາຍຊື່ນັກສຶກສາທີ່ໄດ້ຮຽນ
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflowY: "auto", maxHeight: "70vh", p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-around", mb: 2 }}>
            <Typography
              variant="button"
              onClick={() => setFilter("all")}
              sx={{
                cursor: "pointer",
                borderBottom: filter === "all" ? "2px solid" : "none",
                color: filter === "all" ? "primary.main" : "text.secondary",
                fontWeight: filter === "all" ? "bold" : "normal",
              }}
            >
              ນັກສຶກສາທັງຫມົດ ({students.length})
            </Typography>
            <Typography
              variant="button"
              onClick={() => setFilter("active")}
              sx={{
                cursor: "pointer",
                borderBottom: filter === "active" ? "2px solid" : "none",
                color: filter === "active" ? "primary.main" : "text.secondary",
                fontWeight: filter === "active" ? "bold" : "normal",
              }}
            >
              ນັກສຶກສາກຳລັງໃຊ້ລະບົບ ({activeStudents.length})
            </Typography>
          </Box>

          {filteredStudents.length === 0 ? (
            <Typography variant="body1" color="text.secondary">
              ບໍ່ມີນັກສຶກສາ
            </Typography>
          ) : (
            filteredStudents.map((student) => (
              <Box
                key={student._id}
                sx={{
                  mb: 2,
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid #e0e0e0", // Separator for list items
                  pb: 1,
                }}
              >
                <Avatar
                  src={`path_to_images/${student.image?.url}`}
                  sx={{ mr: 2, width: 40, height: 40 }}
                />
                <Typography variant="body1">
                  {student.firstNameE} {student.lastNameE}
                </Typography>
                {student.isActive && (
                  <CircleIcon sx={{ color: "green", fontSize: 12, ml: 1 }} />
                )}
              </Box>
            ))
          )}
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default TCourseCard;
