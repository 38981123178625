import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Modal,
  Typography,
  List,
  ListItem,
  ListItemSecondaryAction,
  Tooltip,
  InputAdornment,
  Card,
  CardMedia,
} from "@mui/material";
import { Send, Add, Delete } from "@mui/icons-material";

const ChatInput = ({
  newMessage,
  setNewMessage,
  sendMessage,
  isEditing = false,
}) => {
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [description, setDescription] = useState("");

  const openFileModal = () => {
    setFileModalOpen(true);
  };

  const closeFileModal = () => {
    setFileModalOpen(false);
    setFiles([]);
    setDescription("");
  };

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleFileDelete = (file) => {
    setFiles((prevFiles) => prevFiles.filter((f) => f !== file));
  };

  const submitMessageWithFiles = async () => {
    const formData = new FormData();
    formData.append("text", description);
    files.forEach((file) => {
      formData.append("files", file);
    });
    await sendMessage(formData);
    setNewMessage("");
    closeFileModal();
  };

  const renderFilePreview = (file) => {
    const fileType = file.type;

    if (fileType.startsWith("image/")) {
      return (
        <Card key={file.name} sx={{ maxWidth: "100%", mb: 2 }}>
          <CardMedia
            component="img"
            height="140"
            image={URL.createObjectURL(file)}
            alt={file.name}
          />
        </Card>
      );
    }

    if (fileType.startsWith("video/")) {
      return (
        <Card key={file.name} sx={{ maxWidth: "100%", mb: 2 }}>
          <CardMedia
            component="video"
            controls
            height="140"
            src={URL.createObjectURL(file)}
            alt={file.name}
          />
        </Card>
      );
    }

    // For non-image/video files, just show the file name
    return (
      <ListItem key={file.name}>
        {file.name}
        <ListItemSecondaryAction>
          <Tooltip title="Delete File">
            <IconButton edge="end" onClick={() => handleFileDelete(file)}>
              <Delete color="error" />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        padding: "10px",
        backgroundColor: "#fff",
        borderTop: "1px solid #ddd",
      }}
    >
      <TextField
        fullWidth
        variant="outlined"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Type a message..."
        sx={{ marginRight: "10px" }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title="Add Files">
                <IconButton onClick={openFileModal}>
                  <Add />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          if (files.length > 0) {
            submitMessageWithFiles();
          } else {
            sendMessage(newMessage);
          }
        }}
        endIcon={<Send />}
      >
        {isEditing ? "Update" : "Send"}
      </Button>

      {/* File Upload Modal */}
      <Modal open={fileModalOpen} onClose={closeFileModal}>
        <Box
          sx={{
            maxWidth: "400px",
            margin: "auto",
            marginTop: "10%",
            backgroundColor: "#fff",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Typography variant="h6" gutterBottom>
            Upload Files
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={3}
            placeholder="Add a description for the files"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ marginBottom: "10px" }}
          />
          <Button
            variant="outlined"
            component="label"
            sx={{ marginBottom: "10px" }}
          >
            Select Files
            <input
              type="file"
              multiple
              hidden
              onChange={handleFileChange}
              accept="*/*"
            />
          </Button>
          <Box>
            {files.map((file) => (
              <React.Fragment key={file.name}>
                {renderFilePreview(file)}
              </React.Fragment>
            ))}
          </Box>
          <Box sx={{ textAlign: "right", marginTop: "20px" }}>
            <Button onClick={closeFileModal} sx={{ marginRight: "10px" }}>
              Cancel
            </Button>
            <Button variant="contained" onClick={submitMessageWithFiles}>
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ChatInput;
