import React from "react";
import {
  Box,
  Typography,
  Modal,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@mui/material";

const ChatModal = ({ modalData, closeModal }) => {
  console.log("modalData => ", modalData);
  return (
    <Modal open={modalData.open} onClose={closeModal}>
      <Box
        sx={{
          maxWidth: "400px",
          margin: "auto",
          marginTop: "10%",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography variant="h6" gutterBottom>
          {modalData.type || "Details"}
        </Typography>
        {modalData.data && modalData.data.length > 0 ? (
          <List>
            {modalData.data.map((item, index) => (
              <ListItem key={index}>
                {item.avatar && (
                  <ListItemAvatar>
                    <Avatar src={item.avatar} />
                  </ListItemAvatar>
                )}
                <ListItemText primary={item.name || item} />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            variant="body2"
            sx={{ textAlign: "center", marginTop: "20px", color: "gray" }}
          >
            No data to display
          </Typography>
        )}
      </Box>
    </Modal>
  );
};

export default ChatModal;
