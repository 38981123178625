import React, { useEffect, useState } from "react";
import {
  Box,
  Tabs,
  Tab,
  Typography,
  Paper,
  Divider,
  IconButton,
  Table,
  TableCell,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Switch,
  Button,
  CircularProgress,
} from "@mui/material";
import { CheckCircle, Cancel, Save } from "@mui/icons-material";
import { fetchGet, fetchPost } from "../../common/fetchAPI";
import { USER_KEY } from "../../consts/user";
import { useSelector } from "react-redux";
import AlertMessage from "../../common/AlertMessage";
import AttendanceReport from "./report";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
const AttendanceManagement = () => {
  const [selectedCourseIndex, setSelectedCourseIndex] = useState(0);
  const [studentList, setStudentList] = useState([]);
  const [attendanceStatus, setAttendanceStatus] = useState({});
  const [selectedSession, setSelectedSession] = useState(null);
  const [selectedCourseDetails, setSelectedCourseDetails] = useState(null);
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [addStatus, setIsAddStatus] = useState({
    open: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    if (addStatus.open) {
      setTimeout(() => {
        setIsAddStatus({ ...addStatus, open: false });
      }, 3000);
    }
  }, [addStatus]);
  useEffect(() => {
    if (studentList.length > 0) {
      // Set default attendance status to "present" (true) for each student
      setAttendanceStatus(
        studentList.reduce((status, student) => {
          status[student._id] = true;
          return status;
        }, {})
      );
    }
  }, [studentList]);

  const handleCourseSelect = async (event, newIndex) => {
    setSelectedCourseIndex(newIndex);
    setSelectedSession(null); // Reset session selection when course changes
    const courseDetails = courses[newIndex];
    setSelectedCourseDetails(courseDetails);
  };

  const handleStatusChange = (studentId) => {
    setAttendanceStatus((prevStatus) => ({
      ...prevStatus,
      [studentId]: !prevStatus[studentId],
    }));
  };

  const accessmentRedux = useSelector((state) => state.accessment.value);

  const handleSaveAttendance = async () => {
    const attendanceData = studentList.map((student) => ({
      student: student._id,
      courseId: selectedCourseDetails?.course._id,
      status: attendanceStatus[student._id] ? "present" : "absent",
    }));
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);
    const data = {
      course: selectedCourseDetails?.course._id,
      records: attendanceData,
      teacher: userObject?.data?.id,
      date: new Date(),
      time: selectedSession?.times[0].time,
      hour: selectedSession?.times[0].hour,
      day: selectedSession?.day,
      assessmentYear: accessmentRedux,
    };
    setIsLoading(true);
    const response = await fetchPost("/attendance", data);
    if (response)
      setIsAddStatus({
        open: true,
        message: "ບັນທຶກສຳເລັດ",
        type: "success",
      });
    else {
      setIsAddStatus({
        open: true,
        message: "ລະບົບມີບັນຫາ",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  const fetchCourses = async () => {
    const user = localStorage.getItem(USER_KEY);
    const userObject = JSON.parse(user);
    const response = await fetchGet(
      "/teacher/course?teacherId=" + userObject?.data?.id
    );
    setCourses(response || []);
  };

  const fetchStudentByCourse = async (courseId) => {
    const response = await fetchGet("/student/course?courseId=" + courseId);
    setStudentList(response);
  };

  useEffect(() => {
    if (selectedCourseDetails) {
      setStudentList([]);
      fetchStudentByCourse(selectedCourseDetails?._id);
    }
  }, [selectedCourseDetails]);

  useEffect(() => {
    fetchCourses();
  }, []);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box
      sx={{ display: "flex", backgroundColor: "#f0f2f5", minHeight: "100vh" }}
    >
      {/* Course List - Left Sidebar */}

      <AlertMessage
        open={addStatus.open}
        message={addStatus.message}
        type={addStatus.type}
      />
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={selectedCourseIndex}
        onChange={handleCourseSelect}
        sx={{
          borderRight: 1,
          borderColor: "divider",
          marginRight: "10px",
          minWidth: 300,
          backgroundColor: "#ffffff",
          padding: "1rem",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        {courses.map((course) => (
          <Tab
            key={course._id}
            label={
              <Box sx={{ textAlign: "left" }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {course?.course?.subject?.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {`ປີຮຽນ: ${course?.course?.year} ພາກຮຽນ: ${course?.course?.semester}`}
                </Typography>
              </Box>
            }
            sx={{
              alignItems: "flex-start",
              marginBottom: 1,
              borderRadius: "8px",
              "&.Mui-selected": {
                backgroundColor: "#e3f2fd",
                color: "primary.main",
              },
              "&:hover": {
                backgroundColor: "#f5f5f5",
              },
            }}
          />
        ))}
      </Tabs>

      <Box sx={{ width: "100%" }}>
        <Tabs
          sx={{
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            backgroundColor: "white",
          }}
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab
            sx={{ fontWeight: "bold", typography: "subtitle1" }}
            label="ຈັດການ"
          />
          <Tab
            sx={{ fontWeight: "bold", typography: "subtitle1" }}
            label="ລາຍງານ"
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          {/* Main Content Area - Right Panel */}
          <Box sx={{ width: "100%", mt: 1 }}>
            {/* Attendance Management Section */}
            <Paper
              elevation={0}
              sx={{
                p: 3,
                mb: 1,
                backgroundColor: "#ffffff",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                position: "relative",
              }}
            >
              <Typography variant="h6" gutterBottom fontWeight="bold">
                ເລືອກຊົ່ວໂມງສອນທີ່ຕ້ອງການຈັດການ
              </Typography>

              <Divider sx={{ mb: 1 }} />

              <FormControl
                fullWidth
                variant="outlined"
                sx={{ marginBottom: "8px" }}
              >
                <InputLabel size="small">ເລືອກຊົ່ວໂມງສອນ</InputLabel>
                <Select
                  size="small"
                  value={selectedSession || ""}
                  onChange={(event) => setSelectedSession(event.target.value)}
                  label="ເລືອກຊົ່ວໂມງສອນ"
                >
                  {selectedCourseDetails?.timesStudy.map((session, index) =>
                    session.times.map((time, timeIndex) => (
                      <MenuItem key={`${index}-${timeIndex}`} value={session}>
                        {`${session.day} at ${time.time} (${session.studyType})`}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                }}
              >
                <Button
                  sx={{
                    pl: "30px",
                    pr: "30px",
                  }}
                  onClick={handleSaveAttendance}
                  color="primary"
                  variant="contained"
                >
                  {isLoading ? <CircularProgress /> : "ບັນທຶກ"}
                </Button>
              </Box>

              <Divider sx={{ mb: 2 }} />

              {selectedCourseDetails && selectedSession && (
                <TableContainer sx={{ maxHeight: 600 }}>
                  <Table stickyHeader sx={{ backgroundColor: "#fafafa" }}>
                    <TableHead>
                      <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            color="primary"
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            ຊື່ ແລະ ນາມສະກຸນ
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            color="primary"
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            ສະຖານະ
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {studentList.length > 0 ? (
                        studentList.map((student) => (
                          <TableRow
                            key={student._id}
                            hover
                            onClick={() => handleStatusChange(student._id)}
                            sx={{
                              "&:hover": { backgroundColor: "#f9f9f9" },
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                          >
                            <TableCell align="center">
                              <Typography
                                variant="body1"
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                {student?.firstNameL +
                                  " " +
                                  student?.lastNameL +
                                  "studentnumber =>    " +
                                  student?._id}
                              </Typography>
                            </TableCell>
                            <TableCell align="center">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Switch
                                  checked={
                                    attendanceStatus[student._id] || false
                                  }
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    handleStatusChange(student._id);
                                  }}
                                  color="primary"
                                />
                                <Typography
                                  variant="body2"
                                  sx={{
                                    fontWeight: "bold",
                                    ml: 1,
                                    color: attendanceStatus[student._id]
                                      ? "success.main"
                                      : "error.main",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {attendanceStatus[student._id] ? (
                                    <CheckCircle
                                      sx={{ mr: 0.5, color: "success.main" }}
                                      fontSize="small"
                                    />
                                  ) : (
                                    <Cancel
                                      sx={{ mr: 0.5, color: "error.main" }}
                                      fontSize="small"
                                    />
                                  )}
                                  {attendanceStatus[student._id]
                                    ? "ມາ"
                                    : "ບໍ່ມາ"}
                                </Typography>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell align="center" colSpan={2} sx={{ py: 5 }}>
                            <Typography variant="body1" color="textSecondary">
                              ບໍ່ມີຂໍ້ມູນນັກຮຽນ
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Paper>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AttendanceReport courseId={selectedCourseDetails?.course?._id} />
        </TabPanel>
      </Box>
    </Box>
  );
};

export default AttendanceManagement;
