import React, { useState } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Grid,
  Button,
} from "@mui/material";
import {
  ThumbUp,
  Reply,
  Delete,
  InsertDriveFile,
  MoreVert,
  Download,
  Bookmark,
} from "@mui/icons-material";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

const ChatMessage = ({
  message,
  isSender,
  handleAction,
  openModal,
  scrollToMessage,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [contentModalOpen, setContentModalOpen] = useState(false);
  const [modalContents, setModalContents] = useState([]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const markAsImportant = async () => {
    try {
      await axios.put(
        `http://localhost:8000/api/v1/classchat/mark/${message._id}`
      );
    } catch (error) {
      console.error("Error marking message as important:", error);
    }
    handleMenuClose();
  };

  const groupedImages = message.contents.filter((content) =>
    content.contentType.startsWith("image/")
  );

  const nonImageContents = message.contents.filter(
    (content) => !content.contentType.startsWith("image/")
  );

  const senderName = message.student
    ? `${message.student.firstNameL} ${message.student.lastNameL}`
    : `${message.teacher?.teacher?.firstname} ${message.teacher?.teacher?.lastname}`;

  const openContentModal = (contents) => {
    setModalContents(contents);
    setContentModalOpen(true);
  };

  const closeContentModal = () => {
    setContentModalOpen(false);
    setModalContents([]);
  };

  return (
    <Card
      sx={{
        backgroundColor: isSender ? "#dcf8c6" : "#fff",
        borderRadius: "15px",
        marginBottom: "10px",
        marginLeft: isSender ? "100px" : "0px",
        marginRight: isSender ? "0pc" : "100px",
      }}
    >
      <CardContent>
        {/* Parent Message */}
        {message.parentMessage && (
          <Box
            sx={{
              backgroundColor: "#f1f1f1",
              padding: "8px",
              borderLeft: "4px solid #007bff",
              marginBottom: "10px",
              cursor: "pointer",
            }}
            onClick={() => scrollToMessage(message.parentMessage._id)}
          >
            <Typography variant="subtitle2" color="textSecondary">
              {message.parentMessage.student
                ? `${message.parentMessage.student.firstNameL} ${message.parentMessage.student.lastNameL}`
                : `${message.parentMessage.teacher?.teacher?.firstname} ${message.parentMessage.teacher?.teacher?.lastname}`}
            </Typography>
            <Typography variant="body2" noWrap>
              {message.parentMessage.text}
            </Typography>
          </Box>
        )}

        {/* Message Content */}
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          {senderName}
        </Typography>
        <Typography variant="body1">{message.text}</Typography>

        {/* Images and Files */}
        {message.contents.length > 2 ? (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              marginTop: "10px",
              position: "relative",
              maxWidth: "100%",
              cursor: "pointer",
            }}
            onClick={() => openContentModal(message.contents)}
          >
            {message.contents.slice(0, 2).map((content, index) => (
              <Box
                key={content._id}
                component="img"
                src={content.fileUrl}
                alt="attachment"
                sx={{
                  width: "80px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  transform: `rotate(${index * 5}deg)`,
                  position: "absolute",
                  zIndex: message.contents.length - index,
                  left: `${index * 10}px`,
                }}
              />
            ))}
            <Typography
              variant="body2"
              sx={{
                position: "absolute",
                bottom: "5px",
                left: "100px",
                backgroundColor: "#007bff",
                color: "#fff",
                padding: "5px",
                borderRadius: "8px",
              }}
            >
              +{message.contents.length - 2} ເບີ່ງທັງຫມົດດ
            </Typography>
          </Box>
        ) : (
          message.contents.map((content) =>
            content.contentType.startsWith("image/") ? (
              <Box
                key={content._id}
                component="img"
                src={content.fileUrl}
                alt="attachment"
                sx={{
                  maxWidth: "100%",
                  borderRadius: "8px",
                  marginTop: "10px",
                  cursor: "pointer",
                }}
                onClick={() => openContentModal([content])}
              />
            ) : (
              <Box
                key={content._id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <InsertDriveFile sx={{ marginRight: "8px" }} />
                <a
                  href={content.fileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "#007bff" }}
                >
                  ໄຟຣ
                </a>
              </Box>
            )
          )
        )}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="caption" color="textSecondary">
            {new Date(message.timestamp).toLocaleTimeString()}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography>{message?.reactions?.length || 0}</Typography>
            <Tooltip title="ຈຳນວນສະເເດງຄວາມຮູ້ສຶກ">
              <IconButton
                onClick={() =>
                  openModal(
                    "ຈຳນວນສະເເດງຄວາມຮູ້ສຶກ",
                    message.reactions.map((reaction) => ({
                      emoji: reaction.emoji,
                      name: `${reaction?.student?.firstNameL} ${reaction?.student?.lastNameL}`,
                    }))
                  )
                }
              >
                <ThumbUp sx={{ color: "#fdc500" }} />
              </IconButton>
            </Tooltip>
            <Typography ml={"10px"}>{message?.seenBy?.length}</Typography>
            <Tooltip title="ຈຳນວນນັກສຶກສາທີອ່ານແລ້ວ">
              <IconButton
                onClick={() => {
                  openModal(
                    "ຈຳນວນນັກສຶກສາທີອ່ານແລ້ວ (" + message.seenBy?.length + ")",
                    message.seenBy.map((student) => ({
                      // emoji: reaction.emoji,
                      name: `${student?.firstNameL} ${student?.lastNameL}`,
                    }))
                  );
                }}
              >
                <VisibilityIcon sx={{ color: "#4caf50" }} />
              </IconButton>
            </Tooltip>
            {message.isImportant && (
              <Tooltip title="ສຳຄັນ">
                <PriorityHighIcon sx={{ color: "#dd2d4a" }} />
              </Tooltip>
            )}
            <IconButton onClick={handleMenuClick}>
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleAction("reply", message)}>
                <Reply sx={{ marginRight: "8px" }} /> ຕອບກັບ
              </MenuItem>
              <MenuItem onClick={markAsImportant}>
                <Bookmark sx={{ marginRight: "8px" }} /> ຫມາຍເປັນຂໍ້ຄວາມສຳຄັນ
              </MenuItem>
              <MenuItem onClick={() => handleAction("delete", message)}>
                <Delete sx={{ marginRight: "8px" }} /> ລົບ
              </MenuItem>
              {/* <MenuItem
                onClick={() =>
                  openModal(
                    "Mentions",
                    message.mentions.map((mention) => ({
                      avatar: mention.avatar,
                      name: `${mention.firstNameL} ${mention.lastNameL}`,
                    }))
                  )
                }
              >
                <Person sx={{ marginRight: "8px" }} /> Mentions
              </MenuItem> */}
            </Menu>
          </Box>
        </Box>

        {/* Content Modal */}
        <Modal
          open={contentModalOpen}
          onClose={closeContentModal}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              borderRadius: "8px",
              padding: "20px",
              maxHeight: "90%",
              maxWidth: "90%",
              overflowY: "auto",
              boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Grid container spacing={2}>
              {modalContents.map((content) => (
                <Grid item xs={12} sm={6} md={4} key={content._id}>
                  {content.contentType.startsWith("image/") ? (
                    <Box
                      component="img"
                      src={content.fileUrl}
                      alt="attachment"
                      sx={{
                        width: "100%",
                        borderRadius: "8px",
                        marginBottom: "10px",
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: "10px",
                        border: "1px solid #ddd",
                        borderRadius: "8px",
                      }}
                    >
                      <InsertDriveFile sx={{ marginRight: "8px" }} />
                      <Typography
                        variant="body2"
                        sx={{ flex: 1, marginLeft: "10px" }}
                      >
                        {content.fileUrl.split("/").pop()}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        href={content.fileUrl}
                        target="_blank"
                        startIcon={<Download />}
                      >
                        Download
                      </Button>
                    </Box>
                  )}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Modal>
      </CardContent>
    </Card>
  );
};

export default ChatMessage;
