import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Button,
  Modal,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import { fetchGet } from "../../../common/fetchAPI";
import LoadingDialog from "../../../common/LoadingDialog";
import {
  Facebook,
  LinkedIn,
  Twitter,
  Language,
  Edit,
  Email,
  Phone,
  School,
  ArrowBack,
} from "@mui/icons-material";
import InstructorProfile from "./instructor";
import TeacherFeed from "../poosts";

const defaultPlaceholderImage = "path/to/placeholder/image.png"; // Replace with your placeholder image path

const InstructorProfileDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [isFollowerModalOpen, setFollowerModalOpen] = useState(false);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchInstructorData = async () => {
    setIsLoading(true);
    const user = localStorage.getItem("@userKey");
    const userObject = JSON.parse(user);
    const response = await fetchGet("/instructor/" + userObject?.data?.id);
    setIsLoading(false);
    if (response) {
      setProfileData(response);
    } else {
      console.error("Failed to fetch profile data");
    }
  };

  useEffect(() => {
    fetchInstructorData();
  }, []);

  if (isLoading || !profileData) {
    return <LoadingDialog open={isLoading} message={"ກຳລັງໂຫລດ"} />;
  }

  const handleFollowerClick = (follower) => {
    console.log("Clicked follower:", follower);
    // Add your logic here (e.g., navigate to a profile page or show more details)
  };

  return (
    <Box
      sx={{
        backgroundColor: "#f8f9fa",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        gap: 1,
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <InstructorProfile onCancel={() => handleClose()} />
      </Modal>
      {/* Profile Card */}
      <Card
        sx={{
          width: "100%",
          maxWidth: 800,
          position: "relative",
          backgroundColor: "white",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Avatar
            src={profileData.profilePicture || defaultPlaceholderImage}
            sx={{ width: 120, height: 120, marginBottom: 2 }}
          />
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            {`${profileData.teacher.firstname} ${profileData.teacher.lastname}`}
          </Typography>
          <Typography color="textSecondary" sx={{ marginBottom: 2 }}>
            {profileData.bio}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => setFollowerModalOpen(true)}
            startIcon={<School />}
          >
            ຈຳນວນຜູ້ຕິດຕາມ: {profileData.follower.length}
          </Button>
          <IconButton
            sx={{ position: "absolute", top: 10, right: 10 }}
            onClick={() => handleOpen()}
          >
            <Edit />
          </IconButton>
        </CardContent>
      </Card>

      {/* Contact Information Card */}
      <Card
        sx={{
          width: "100%",
          maxWidth: 800,
          backgroundColor: "white",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Email color="primary" />
          <Box>
            <Typography variant="h6">ຂໍ້ມຸນຕິດຕໍ່</Typography>
            <Typography>ອີເມວ: {profileData.contactEmail}</Typography>
            <Typography>ເບີໂທລະສັບ: {profileData.phoneNumber}</Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Education Card */}
      <Card
        sx={{
          width: "100%",
          maxWidth: 800,
          backgroundColor: "white",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <School color="secondary" /> ການສຶກສາ
          </Typography>
          {profileData.education.map((edu, index) => (
            <Box key={index} sx={{ marginBottom: 2, paddingLeft: 4 }}>
              <Typography variant="body1">
                {edu.degree} in {edu.fieldOfStudy}
              </Typography>
              <Typography color="textSecondary">
                {edu.institution} ({edu.yearOfGraduation})
              </Typography>
            </Box>
          ))}
        </CardContent>
      </Card>
      <Card
        sx={{
          width: "100%",
          maxWidth: 800,
          backgroundColor: "white",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 1,
          }}
        >
          {profileData.socialLinks.map((link, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                width: "100%",
              }}
            >
              <IconButton
                href={link.link}
                target="_blank"
                color="primary"
                sx={{ padding: 0 }}
              >
                {["facebook", "linkedin", "twitter", "website"].includes(
                  link.name
                ) ? (
                  link.name === "facebook" ? (
                    <Facebook />
                  ) : link.name === "linkedin" ? (
                    <LinkedIn />
                  ) : link.name === "twitter" ? (
                    <Twitter />
                  ) : (
                    <Language />
                  )
                ) : (
                  <Language /> // Default web icon for any other link name
                )}
              </IconButton>

              <Typography
                variant="body2"
                color="textPrimary"
                sx={{ wordBreak: "break-all", flexGrow: 1 }}
              >
                <a
                  href={link.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  {link.link}
                </a>
              </Typography>
            </Box>
          ))}
        </CardContent>
      </Card>
      <TeacherFeed />
      <Modal
        open={isFollowerModalOpen}
        onClose={() => setFollowerModalOpen(false)}
      >
        <Box
          sx={{
            backgroundColor: "white",
            padding: 4,
            borderRadius: 2,
            maxWidth: 400,
            margin: "auto",
            marginTop: "10%",
            boxShadow: 3,
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            ຜູ້ຕີດຕາມ
          </Typography>
          <List>
            {profileData.follower.map((follower) => (
              <ListItem
                key={follower._id}
                button
                onClick={() => handleFollowerClick(follower)}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#f0f0f0",
                  },
                }}
              >
                {follower.photo ? (
                  <Avatar
                    src={follower.photo}
                    alt={`${follower.firstNameL} ${follower.lastNameL}`}
                    sx={{ marginRight: 2 }}
                  />
                ) : (
                  <Avatar sx={{ marginRight: 2 }}>
                    {follower.firstNameL.charAt(0)}
                    {follower.lastNameL.charAt(0)}
                  </Avatar>
                )}
                <ListItemText
                  primary={`${follower.firstNameL} ${follower.lastNameL}`}
                  secondary={`Student Number: ${follower.studentNumber}`}
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>
    </Box>
  );
};

export default InstructorProfileDetail;
