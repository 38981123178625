import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Paper,
  Avatar,
  IconButton,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import {
  CloudUpload as CloudUploadIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useParams } from "react-router-dom";
import { fetchGet, fetchPost } from "../../../common/fetchAPI";
import AlertMessage from "../../../common/AlertMessage";
import LoadingDialog from "../../../common/LoadingDialog";
import useReactRouter from "use-react-router";

const defaultPlaceholderImage = "path/to/placeholder/image.png"; // Replace with the path to your placeholder image

const InstructorProfile = ({ onCancel }) => {
  const { id } = useParams();
  const [actionStatus, setActionStatus] = useState({
    open: false,
    message: "",
    type: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [profileData, setProfileData] = useState({
    teacher: id,
    bio: "",
    contactEmail: "",
    phoneNumber: "",
    socialLinks: [{ name: "", link: "" }],
    education: [
      { degree: "", fieldOfStudy: "", institution: "", yearOfGraduation: "" },
    ],
    profilePicture: null,
  });

  const [profilePictureUrl, setProfilePictureUrl] = useState(
    defaultPlaceholderImage
  );

  const fetchInstructorData = async () => {
    setIsLoading(true);
    const user = localStorage.getItem("@userKey");
    const userObject = JSON.parse(user);
    const response = await fetchGet("/instructor/" + userObject?.data?.id);
    if (response) {
      setProfileData({ teacher: id, ...response });
      if (response.profilePicture) {
        setProfilePictureUrl(response.profilePicture);
      } else {
        setProfilePictureUrl(defaultPlaceholderImage);
      }
    } else {
      setActionStatus({
        open: true,
        message: "ລະບົບມິບັນຫາໃນການສະເເດງຂໍ້ມຸນອາຈານສອນ",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (actionStatus.open) {
      setTimeout(() => {
        setActionStatus({ ...actionStatus, open: false });
      }, 3000);
    }
  }, [actionStatus]);

  useEffect(() => {
    fetchInstructorData();
  }, []);

  const [errors, setErrors] = useState({});

  const validateFields = () => {
    let tempErrors = {};
    if (!profileData.bio) tempErrors.bio = "ກະລຸນາປ້ອນປະຫວັດຢ່າງຫຍໍ້";
    if (!profileData.contactEmail) {
      tempErrors.contactEmail = "ກະລຸນາປ້ອນອີເມວ";
    } else if (!/\S+@\S+\.\S+/.test(profileData.contactEmail)) {
      tempErrors.contactEmail = "ອີເມວບໍ່ຖືກຕ້ອງ";
    }
    if (!profileData.phoneNumber) {
      tempErrors.phoneNumber = "ກະລຸນາປ້ອນເບີໂທ";
    } else if (!/^\d{8,15}$/.test(profileData.phoneNumber)) {
      tempErrors.phoneNumber = "ເບີໂທບໍ່ຖືກຕ້ອງ";
    }
    profileData.socialLinks.forEach((link, index) => {
      if (!link.name) tempErrors[`socialName${index}`] = "ກະລຸນາປ້ອນຊື່ແພລດຟອມ";
      if (!link.link) tempErrors[`socialLink${index}`] = "ກະລຸນາປ້ອນລິ້ງ";
    });
    profileData.education.forEach((edu, index) => {
      if (!edu.degree) tempErrors[`degree${index}`] = "ກະລຸນາປ້ອນລະດັບການສຶກສາ";
      if (!edu.fieldOfStudy)
        tempErrors[`fieldOfStudy${index}`] = "ກະລຸນາປ້ອນສາຂາວິຊາ";
      if (!edu.institution)
        tempErrors[`institution${index}`] = "ກະລຸນາປ້ອນສະຖາບັນ";
      if (!edu.yearOfGraduation)
        tempErrors[`yearOfGraduation${index}`] = "ກະລຸນາປ້ອນປີສຳເລັດ";
    });
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateFields()) return;

    const formData = new FormData();
    for (const key in profileData) {
      if (key === "profilePicture" && profileData[key]) {
        formData.append(key, profileData[key]);
      } else {
        formData.append(key, JSON.stringify(profileData[key]));
      }
    }

    setIsLoading(true);
    const response = await fetchPost("/instructor", formData);

    if (response) {
      setActionStatus({
        open: true,
        message: "ບັນທຶກສຳເລັດ",
        type: "success",
      });
      fetchInstructorData();
    } else {
      setActionStatus({
        open: true,
        message: "ມິບັນຫາໃນການບັນທຶກ",
        type: "error",
      });
    }
    setIsLoading(false);
  };

  const handleAddSocialLink = () => {
    setProfileData({
      ...profileData,
      socialLinks: [...profileData.socialLinks, { name: "", link: "" }],
    });
  };

  const handleRemoveSocialLink = (index) => {
    const updatedLinks = profileData.socialLinks.filter((_, i) => i !== index);
    setProfileData({ ...profileData, socialLinks: updatedLinks });
  };

  const handleRemoveEducation = (index) => {
    const updatedEducation = profileData.education.filter(
      (_, i) => i !== index
    );
    setProfileData({ ...profileData, education: updatedEducation });
  };

  const handleAddEducation = () => {
    setProfileData({
      ...profileData,
      education: [
        ...profileData.education,
        { degree: "", fieldOfStudy: "", institution: "", yearOfGraduation: "" },
      ],
    });
  };

  return (
    <Box
      sx={{
        paddingTop: "10px",
        paddingBottom: "10px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyItems: "center",
      }}
    >
      <LoadingDialog open={isLoading} message={"ກຳລັງໂຫລດ"} />
      <AlertMessage
        handleClose={() => {
          setActionStatus({
            ...actionStatus,
            open: false,
          });
        }}
        type={actionStatus.type}
        open={actionStatus.open}
        message={actionStatus.message}
      />
      <Paper
        elevation={3}
        sx={{
          maxHeight: "800px",
          overflowY: "auto", // Enables vertical scrolling when content overflows
          padding: 4,
          width: "800px",
        }}
      >
        <Typography variant="h5" sx={{ mb: 2, textAlign: "center" }}>
          ແກ້ໄຂໂປຣໄຟລ
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Avatar
              sx={{ width: 100, height: 100, margin: "0 auto" }}
              src={profilePictureUrl}
              alt="Profile"
            />
            <IconButton color="primary" component="label">
              <CloudUploadIcon />
              <input
                type="file"
                hidden
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    setProfileData({ ...profileData, profilePicture: file });
                    const objectUrl = URL.createObjectURL(file);
                    setProfilePictureUrl(objectUrl);

                    // Clean up the created URL after component unmount
                    return () => URL.revokeObjectURL(objectUrl);
                  }
                }}
                accept="image/*"
              />
            </IconButton>
            <Typography variant="caption">ອັບໂຫລດຮູບໂປຣໄຟລ</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="ປະຫວັດຢ່າງຫຍໍ້"
              name="bio"
              value={profileData.bio}
              onChange={(e) =>
                setProfileData({ ...profileData, bio: e.target.value })
              }
              multiline
              rows={4}
              error={!!errors.bio}
              helperText={errors.bio}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="ອີເມວ"
              name="contactEmail"
              type="email"
              value={profileData.contactEmail}
              onChange={(e) =>
                setProfileData({ ...profileData, contactEmail: e.target.value })
              }
              error={!!errors.contactEmail}
              helperText={errors.contactEmail}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="ເບີໂທ"
              name="phoneNumber"
              value={profileData.phoneNumber}
              onChange={(e) =>
                setProfileData({ ...profileData, phoneNumber: e.target.value })
              }
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Typography mb={2} variant="h6">
              ລິ້ງໂຊຊຽວ
            </Typography>
            {profileData.socialLinks.map((link, index) => (
              <Box key={index} sx={{ display: "flex", mb: 1 }}>
                <TextField
                  label="ຊື່ແພລດຟອມ"
                  value={link.name}
                  onChange={(e) => {
                    const updatedLinks = [...profileData.socialLinks];
                    updatedLinks[index].name = e.target.value;
                    setProfileData({
                      ...profileData,
                      socialLinks: updatedLinks,
                    });
                  }}
                  error={!!errors[`socialName${index}`]}
                  helperText={errors[`socialName${index}`]}
                  sx={{ flex: 1, mr: 2 }}
                />
                <TextField
                  label="ລິ້ງ"
                  value={link.link}
                  onChange={(e) => {
                    const updatedLinks = [...profileData.socialLinks];
                    updatedLinks[index].link = e.target.value;
                    setProfileData({
                      ...profileData,
                      socialLinks: updatedLinks,
                    });
                  }}
                  error={!!errors[`socialLink${index}`]}
                  helperText={errors[`socialLink${index}`]}
                  sx={{ flex: 1, mr: 2 }}
                />
                <IconButton
                  color="error"
                  onClick={() => handleRemoveSocialLink(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
            <Button startIcon={<AddIcon />} onClick={handleAddSocialLink}>
              ເພີ່ມລິ້ງໂຊຊຽວ
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography mb={2} variant="h6">
              ປະຫວັດການສຶກສາ
            </Typography>
            {profileData.education.map((edu, index) => (
              <Box key={index} sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      label="ລະດັບການສຶກສາ"
                      value={edu.degree}
                      onChange={(e) => {
                        const updatedEducation = [...profileData.education];
                        updatedEducation[index].degree = e.target.value;
                        setProfileData({
                          ...profileData,
                          education: updatedEducation,
                        });
                      }}
                      error={!!errors[`degree${index}`]}
                      helperText={errors[`degree${index}`]}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="ສາຂາວິຊາ"
                      value={edu.fieldOfStudy}
                      onChange={(e) => {
                        const updatedEducation = [...profileData.education];
                        updatedEducation[index].fieldOfStudy = e.target.value;
                        setProfileData({
                          ...profileData,
                          education: updatedEducation,
                        });
                      }}
                      error={!!errors[`fieldOfStudy${index}`]}
                      helperText={errors[`fieldOfStudy${index}`]}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      label="ສະຖາບັນ"
                      value={edu.institution}
                      onChange={(e) => {
                        const updatedEducation = [...profileData.education];
                        updatedEducation[index].institution = e.target.value;
                        setProfileData({
                          ...profileData,
                          education: updatedEducation,
                        });
                      }}
                      error={!!errors[`institution${index}`]}
                      helperText={errors[`institution${index}`]}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      label="ປີສຳເລັດ"
                      type="number"
                      value={edu.yearOfGraduation}
                      onChange={(e) => {
                        const updatedEducation = [...profileData.education];
                        updatedEducation[index].yearOfGraduation =
                          e.target.value;
                        setProfileData({
                          ...profileData,
                          education: updatedEducation,
                        });
                      }}
                      error={!!errors[`yearOfGraduation${index}`]}
                      helperText={errors[`yearOfGraduation${index}`]}
                      fullWidth
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <IconButton
                      color="error"
                      onClick={() => handleRemoveEducation(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            ))}
            <Button startIcon={<AddIcon />} onClick={handleAddEducation}>
              ເພີ່ມປະຫວັດການສຶກສາ
            </Button>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignContent: "center",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            color="error"
            sx={{ mt: 3, mr: 3, width: "120px", height: "45px" }}
            onClick={() => {
              onCancel();
            }}
          >
            ຍົກເລີກ
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 3, px: 4, width: "120px", height: "45px" }}
            onClick={handleSubmit}
          >
            ບັນທຶກ
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default InstructorProfile;
