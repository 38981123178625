import React, { useEffect, useState, useRef } from "react";
import { Box, Typography, Button } from "@mui/material";
import ChatInput from "../components/ChatInput";
import ChatMessage from "../components/ChatMessage";
import ChatModal from "../components/ChatModal";
import {
  fetchMessages,
  sendMessage,
  deleteMessage,
  reactToMessage,
  markImportantMessage,
} from "../services/chatService";
import socket from "../utils/socket";

const ClassChat = () => {
  const roomName = "socketifroomid";
  const userId = "64fecbba39503c8de466a1c6";

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [replyTo, setReplyTo] = useState(null);
  const [editMessage, setEditMessage] = useState(null);
  const [modalData, setModalData] = useState({
    open: false,
    type: "",
    data: [],
  });

  const messageRefs = useRef({}); // Store references to each message

  useEffect(() => {
    socket.emit("joinRoom", roomName);

    const getMessages = async () => {
      const data = await fetchMessages(roomName);
      setMessages(data);
    };

    getMessages();

    socket.on("newMessage", (message) => {
      setMessages((prev) => [...prev, message]);
    });

    socket.on("messageUpdated", (updatedMessage) => {
      setMessages((prev) =>
        prev.map((msg) =>
          msg._id === updatedMessage._id ? updatedMessage : msg
        )
      );
    });

    socket.on("messageDeleted", (messageId) => {
      setMessages((prev) => prev.filter((msg) => msg._id !== messageId));
    });

    return () => {
      socket.off("newMessage");
      socket.off("messageUpdated");
      socket.off("messageDeleted");
      // socket.emit("leaveRoom", roomName);
    };
  }, [roomName]);

  const handleSendMessage = async (formData) => {
    try {
      if (formData instanceof FormData) {
        // Handle FormData submission (file + message)
        formData.append("room", roomName);
        formData.append("sender", userId);
        formData.append("type", "STUDENT");
        if (replyTo) formData.append("parentMessage", replyTo._id);

        await sendMessage(formData, true); // Indicate it's FormData in the sendMessage function
      } else {
        // Handle plain text submission
        await sendMessage({
          room: roomName,
          text: formData,
          sender: userId,
          type: "STUDENT",
          parentMessage: replyTo?._id || null,
        });
      }
      setNewMessage("");
      setReplyTo(null);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleAction = async (action, message) => {
    if (action === "reply") setReplyTo(message);
    if (action === "edit") {
      setNewMessage(message.text);
      setEditMessage(message);
    }
    if (action === "delete") {
      await deleteMessage(message._id);
    }
    if (action === "react") {
      await reactToMessage(message._id, { studentId: userId, emoji: "👍" });
    }
    if (action === "markImportant") {
      try {
        const updatedMessage = await markImportantMessage(message._id);
        setMessages((prev) =>
          prev.map((msg) =>
            msg._id === updatedMessage._id ? updatedMessage : msg
          )
        );
        // Emit updated message to all clients
        socket.emit("messageUpdated", updatedMessage);
      } catch (error) {
        console.error("Error marking message as important:", error);
      }
    }
  };

  const scrollToMessage = (messageId) => {
    if (messageRefs.current[messageId]) {
      messageRefs.current[messageId].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "600px",
        margin: "auto",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5" sx={{ textAlign: "center", padding: "10px" }}>
        Class Chat - {roomName}
      </Typography>
      <Box
        sx={{
          flex: 1,
          overflowY: "scroll",
          padding: "10px",
          backgroundColor: "#f7f9fb",
        }}
      >
        {messages.map((msg) => (
          <div key={msg._id} ref={(el) => (messageRefs.current[msg._id] = el)}>
            <ChatMessage
              message={msg}
              isSender={
                msg.student?._id === userId || msg.teacher?._id === userId
              }
              handleAction={handleAction}
              openModal={(type, data) =>
                setModalData({ open: true, type, data })
              }
              scrollToMessage={scrollToMessage}
            />
          </div>
        ))}
      </Box>
      {replyTo && (
        <Box
          sx={{
            padding: "10px",
            backgroundColor: "#e0f7fa",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body2">Replying to: {replyTo.text}</Typography>
          <Button size="small" onClick={() => setReplyTo(null)}>
            Cancel
          </Button>
        </Box>
      )}
      <ChatInput
        newMessage={newMessage}
        setNewMessage={setNewMessage}
        sendMessage={handleSendMessage}
        isEditing={!!editMessage}
      />
      <ChatModal
        modalData={modalData}
        closeModal={() => setModalData({ open: false })}
      />
    </Box>
  );
};

export default ClassChat;
