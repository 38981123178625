import React, { Component } from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import ApolloClient from "apollo-boost";
import "./index.css";
import store from "./redux/store";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
// component ---------------------------------------------------->
import Routes from "./Routes";
import { USER_KEY } from "./consts/user";

library.add(fab, fas);
dom.watch();

const client = new ApolloClient({
  // uri: "https://nuol-api.lailaolab.com/",
  // uri: "http://13.214.171.46:7070", //DB staging
  // uri: "https://7nhp7tu4jk.execute-api.ap-southeast-1.amazonaws.com/", // DB UAT
  //uri: "http://13.251.5.178:2001/",
  uri: "https://graphql-api.laodevhub.com/",
  //uri: "http://localhost:7071/",

  request: (operation) => {
    const user = localStorage.getItem(USER_KEY);
    if (user) {
      const token = JSON.parse(user)["accessToken"];
      operation.setContext({
        headers: {
          Authorization: token ? "Nuol " + token : "",
          Platform: "TEACHER",
        },
      });
      return;
    }
    operation.setContext({
      headers: {
        Platform: "TEACHER",
      },
    });
  },
  onError: (error) => {
    console.log(" app error");
    console.log(error);
    if (error?.response?.errors?.length > 0) {
      if (
        error?.response?.errors[0]?.message === "TokenExpiredError: jwt expired"
      ) {
        localStorage.clear();
      }
    }
    console.log("fetch error: ", error?.response?.errors[0]?.message);
  },
});
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGrid: false,
    };
  }

  render() {
    return (
      <Provider store={store}>
        <ApolloProvider client={client}>
          <Routes />
        </ApolloProvider>
      </Provider>
    );
  }
}

export default App;
